import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import './CreditUser.css';

const API_BASE_URL = '/backend';

function CreditUser() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [amount, setAmount] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
      fetchUsers();
  }, []);

  const fetchUsers = async () => {
      try {
          const response = await axios.get(`${API_BASE_URL}/handlers/get_users.php`);
          if (response.data.success) {
              setUsers(response.data.users);
          }
      } catch (err) {
          setError('Failed to fetch users');
      }
  };

  const handleCredit = async (e) => {
      e.preventDefault();
        
      if (!selectedUser || !amount) {
          setError('Please fill in all fields');
          return;
      }

      try {
          const formData = new FormData();
          formData.append('user_id', selectedUser);
          formData.append('amount', amount);

          const response = await axios.post(
              `${API_BASE_URL}/handlers/credit_user.php`,
              formData,
              { headers: { 'Content-Type': 'multipart/form-data' } }
          );

          if (response.data.success) {
              setSuccess('User credited successfully!');
              setAmount('');
              setSelectedUser('');
              fetchUsers(); // Refresh user list to show updated balance
          } else {
              setError(response.data.message);
          }
      } catch (err) {
          setError('Failed to credit user');
      }
  };

  return (
      <div className="layout">
          <Sidebar />
          <div className="main-content">
              <Header />
              <div className="credit-user">
                  <h1>Credit User</h1>
                    
                  {success && <div className="success-message">{success}</div>}
                  {error && <div className="error-message">{error}</div>}

                  <div className="credit-form-container">
                      <form onSubmit={handleCredit} className="credit-form">
                          <div className="form-group">
                              <label>Select User:</label>
                              <select 
                                  value={selectedUser} 
                                  onChange={(e) => setSelectedUser(e.target.value)}
                                  required
                              >
                                  <option value="">Select a user</option>
                                  {users.map(user => (
                                      <option key={user.user_id} value={user.user_id}>
                                          {user.username} - Current Balance: {user.balance} FC
                                      </option>
                                  ))}
                              </select>
                          </div>

                          <div className="form-group">
                              <label>Credit Amount (FC):</label>
                              <input
                                  type="number"
                                  value={amount}
                                  onChange={(e) => setAmount(e.target.value)}
                                  min="1"
                                  required
                                  placeholder="Enter amount"
                              />
                          </div>

                          <button type="submit" className="credit-btn">Credit User</button>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default CreditUser;