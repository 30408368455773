import React from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

function TransactionManagement() {
    return (
        <div className="layout">
            <Sidebar />
            <div className="main-content">
                <Header />
                <h1>Transaction Management</h1>
                <p>This page is under construction.</p>
            </div>
        </div>
    );
}

export default TransactionManagement;
