import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { FaCoins, FaDice, FaTrophy, FaChartLine, FaUsers } from 'react-icons/fa';
import './Profile.css';

const API_BASE_URL = '/backend';

function Profile() {
    const { username } = useParams();
    const [profile, setProfile] = useState({
        username: '',
        email: '',
        favorite_team: '',
        balance: 0,
        total_bets: 0,
        win_rate: 0,
        leaderboard_score: 0
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchError, setSearchError] = useState('');
    const [teams, setTeams] = useState([]);
    const [showBetDetailsModal, setShowBetDetailsModal] = useState(false);
    const [selectedBet, setSelectedBet] = useState(null);

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) return 'Good Morning';
        if (hour < 18) return 'Good Afternoon';
        return 'Good Evening';
    };

    useEffect(() => {
        if (username) {
            fetchData(username);
            fetchTeams();
        }
    }, [username]);

    const fetchData = async (username) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/profile.php?username=${username}`);
            if (response.data.success) {
                setProfile(response.data.profile);
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    };

      const fetchTeams = async () => {
          try {
              const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
              if (response.data.status === 200) {
                  setTeams(response.data.data);
              }
          } catch (error) {
              console.error('Error fetching teams:', error);
          }
      };

      const handleShowBetDetails = (bet) => {
          setSelectedBet(bet);
          setShowBetDetailsModal(true);
      };
    
      const getTeamLogo = (teamName) => {
          const team = teams.find(team => team.name === teamName);
          return team ? `${API_BASE_URL}/${team.logo}` : '';
      };

      const handleSearch = async () => {
          try {
              setSearchError('');
              if (!searchQuery.trim()) {
                  setSearchError('Please enter a username to search');
                  return;
              }
              const response = await axios.get(`${API_BASE_URL}/handlers/search_users.php?query=${searchQuery}`);
              if (response.data.success && response.data.users.length > 0) {
                  const currentUserId = localStorage.getItem('userId');
                  const filteredUsers = response.data.users.filter(user => user.user_id !== currentUserId);
                  setSearchResults(filteredUsers);
                  if (filteredUsers.length === 0) {
                      setSearchError('No users found matching your search');
                  }
              } else {
                  setSearchResults([]);
                  setSearchError('No users found matching your search');
              }
          } catch (error) {
              setSearchResults([]);
              setSearchError('Error searching for users');
          }
      };

      const addFriend = async (friendId) => {
          try {
              const userId = localStorage.getItem('userId');
              const response = await axios.post(`${API_BASE_URL}/handlers/add_friend.php`, {
                  user_id: userId,
                  friend_id: friendId
              });
              if (response.data.success) {
                  fetchData();
                  setSearchResults([]);
                  setSearchQuery('');
              } else {
                  setSearchError(response.data.message);
              }
          } catch (error) {
              setSearchError('Failed to add friend');
          }
      };

      return (
          <div className="profile-container">
              <div className="profile-header">
                  <div className="profile-info">
                      <div className="greeting-container">
                          <h1>{getGreeting()}, {profile.username}</h1>
                      </div>
                  </div>
                  <div className="profile-stats">
                      <div className="stat-card">
                          <FaCoins className="stat-icon" />
                          <h3>Balance</h3>
                          <span>{profile.balance} FC</span>
                      </div>
                      <div className="stat-card">
                          <FaDice className="stat-icon" />
                          <h3>Total Bets</h3>
                          <span>{profile.total_bets || 0}</span>
                      </div>
                      <div className="stat-card">
                          <FaTrophy className="stat-icon" />
                          <h3>Win Rate</h3>
                          <span>{profile.win_rate || 0}%</span>
                      </div>
                      <div className="stat-card">
                          <FaChartLine className="stat-icon" />
                          <h3>Leaderboard Score</h3>
                          <span>{profile.leaderboard_score || 0}</span>
                      </div>
                      <div className="stat-card">
                          <FaUsers className="stat-icon" />
                          <h3>Friends</h3>
                          <span>{profile.friends?.length || 0}</span>
                      </div>
                      <div className="stat-card">
                          <h3>Favorite Team</h3>
                          <span>{profile.favorite_team}</span>
                      </div>
                  </div>
              </div>
              <div className="search-section card">
                  <h2>Find Friends</h2>
                  <div className="search-box">
                      <input
                          type="text"
                          placeholder="Search users..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <button onClick={handleSearch}>Search</button>
                  </div>
                  {searchError && <div className="search-error">{searchError}</div>}
                  {searchResults.length > 0 && (
                      <div className="search-results">
                          {searchResults.map(user => (
                              <div key={user.user_id} className="user-search-card">
                                  <div className="user-search-info">
                                      <div className="user-primary-info">
                                          <span className="username">{user.username}</span>
                                          <div className="team-info">
                                              <img src={getTeamLogo(user.favorite_team)} alt={user.favorite_team} className="team-logo-tiny" />
                                              <span className="favorite-team">{user.favorite_team}</span>
                                          </div>
                                      </div>
                                      <div className="user-stats">
                                          <span>Total Bets: {user.total_bets || 0}</span>
                                          <span>Win Rate: {user.win_rate || 0}%</span>
                                          <span>Score: {user.leaderboard_score || 0}</span>
                                      </div>
                                  </div>
                                  <button onClick={() => addFriend(user.user_id)} className="add-friend-btn">
                                      Add Friend
                                  </button>
                              </div>
                          ))}
                      </div>
                  )}
              </div>
              <div className="recent-bets card">
                  <h2>Recent Bets</h2>
                  <table className="bets-table">
                      <thead>
                          <tr>
                              <th>Ref</th>
                              <th>From</th>
                              <th>Team A</th>
                              <th>Team B</th>
                              <th>Amount</th>
                              <th>Status</th>
                          </tr>
                      </thead>
                      <tbody>
                          {profile.recent_bets?.slice(0, 3).map(bet => (
                              <tr key={bet.bet_id}>
                                  <td>
                                      <span className="reference" onClick={() => handleShowBetDetails(bet)}>
                                          {bet.unique_code?.toUpperCase() || `${bet.bet_id}DNRBKCC`}
                                      </span>
                                  </td>
                                  <td>{bet.user1_name}</td>
                                  <td>
                                      <div className="team-info">
                                          <img src={getTeamLogo(bet.team_a)} alt={bet.team_a} className="team-logo" />
                                          <span>{bet.team_a}</span>
                                          {bet.bet_choice_user2 === 'team_a_win' && <span className="pick-badge">Your Pick</span>}
                                      </div>
                                  </td>
                                  <td>
                                      <div className="team-info">
                                          <img src={getTeamLogo(bet.team_b)} alt={bet.team_b} className="team-logo" />
                                          <span>{bet.team_b}</span>
                                          {bet.bet_choice_user2 === 'team_b_win' && <span className="pick-badge">Your Pick</span>}
                                      </div>
                                  </td>
                                  <td>{bet.amount_user2} FanCoins</td>
                                  <td>
                                      <span className={`status ${bet.bet_status}`}>
                                          {bet.bet_status}
                                      </span>
                                  </td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
              </div>
            <div className="friends-section">
                <h2>Friends ({profile.friends?.length || 0})</h2>
                <div className="friends-grid">
                    {profile.friends?.map(friend => (
                        <div key={friend.user_id} className="friend-card">
                            <div className="friend-info">
                                <Link to={`/profile/${friend.username}`}>
                                    <h3>{friend.username}</h3>
                                </Link>
                                <img 
                                    src={getTeamLogo(friend.favorite_team)} 
                                    alt={friend.favorite_team} 
                                    className="team-logo"
                                />
                                <span>{friend.favorite_team}</span>
                            </div>
                            <div className="friend-stats">
                                <div>
                                    <h4>Bets</h4>
                                    <span>{friend.total_bets}</span>
                                </div>
                                <div>
                                    <h4>Win Rate</h4>
                                    <span>{friend.win_rate}%</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {showBetDetailsModal && selectedBet && (
                <div className="modal-overlay">
                    <div className="bet-details-modal">
                        <button className="close-button" onClick={() => setShowBetDetailsModal(false)}>×</button>
                                                  <div className="modal-left">
                                                      <h3 className="reference-title">
                                                          Bet Reference: {selectedBet.unique_code?.toUpperCase() || `${selectedBet.bet_id}DNRBKCC`}
                                                      </h3>
                            
                                                      <div className="teams-match">
                                                          <div className={`team-card user1`}>
                                                              <img src={getTeamLogo(selectedBet.team_a)} alt={selectedBet.team_a} />
                                                              <div className="team-name">{selectedBet.team_a}</div>
                                                              <div className="team-odds user1">Odds: {selectedBet.odds_team_a}</div>
                                                              <div className="team-user">{selectedBet.user1_name}</div>
                                                          </div>
                                
                                                          <div className="vs-badge">VS</div>
                                
                                                          <div className={`team-card user2`}>
                                                              <img src={getTeamLogo(selectedBet.team_b)} alt={selectedBet.team_b} />
                                                              <div className="team-name">{selectedBet.team_b}</div>
                                                              <div className="team-odds user2">Odds: {selectedBet.odds_team_b}</div>
                                                              <div className="team-user">{selectedBet.user2_name}</div>
                                                          </div>
                                                      </div>
                                                  </div>
                        <div className="modal-right">
                            <div className="status-badge-large" data-status={selectedBet.bet_status}>
                                {selectedBet.bet_status.toUpperCase()}
                            </div>

                            <div className="bet-details-grid">
                                <div className="detail-item amount">
                                    <div className="detail-label">Your Bet Amount</div>
                                    <div className="detail-value amount">{selectedBet.amount_user2} FanCoins</div>
                                </div>
                                <div className="detail-item return">
                                    <div className="detail-label">Your Potential Return</div>
                                    <div className="detail-value return">{selectedBet.potential_return_win_user2} FanCoins</div>
                                </div>
                                <div className="detail-item">
                                    <div className="detail-label">Created At</div>
                                    <div className="detail-value">{new Date(selectedBet.created_at).toLocaleString()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Profile;