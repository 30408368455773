import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './UserLogin.css';

const API_BASE_URL = '/backend';

function UserLogin() {
    const [credentials, setCredentials] = useState({ usernameOrEmail: '', password: '' });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await axios.post(`${API_BASE_URL}/handlers/user_login.php`, credentials);
            if (response.data.success) {
                localStorage.setItem('userId', response.data.userId);
                localStorage.setItem('username', response.data.username);
                const redirectPath = sessionStorage.getItem('redirectAfterLogin');
                if (redirectPath) {
                    sessionStorage.removeItem('redirectAfterLogin');
                    navigate(redirectPath);
                } else {
                    navigate('/user/dashboard');
                }
            } else {
                setError(response.data.message || 'Login failed');
            }
        } catch (err) {
            setError('An error occurred during login');
        }
    };

    return (
        <div className="user-login">
            <h1>FanBet247 User Login</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="usernameOrEmail"
                    value={credentials.usernameOrEmail}
                    onChange={handleInputChange}
                    placeholder="Username or Email"
                    required
                />
                <input
                    type="password"
                    name="password"
                    value={credentials.password}
                    onChange={handleInputChange}
                    placeholder="Password"
                    required
                />
                <button type="submit">Login</button>
            </form>
            {error && <div className="error-message">{error}</div>}
            <p>Don't have an account? <a href="/user/register">Register here</a></p>
        </div>
    );
}

export default UserLogin;