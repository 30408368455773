import React, { useState } from 'react';
import axios from 'axios';
import './AdminRegisterPage.css';

const AdminRegisterPage = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        role: 'moderator'
    });
    const [message, setMessage] = useState('');

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');

        try {
            const response = await axios.post('/backend/handlers/admin_register.php', formData);
            if (response.status === 201) {
                setMessage('Admin registered successfully.');
                setFormData({ username: '', email: '', password: '', role: 'moderator' });
            } else {
                setMessage('Registration failed: ' + response.data.message);
            }
        } catch (error) {
            setMessage('An error occurred. Please try again.');
            console.error('Error:', error);
        }
    };

    return (
        <div className="admin-register-page">
            <h2>Admin Registration (Test Only)</h2>
            {message && <div className="message">{message}</div>}
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    placeholder="Username"
                    required
                />
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                    required
                />
                <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="Password"
                    required
                />
                <select
                    name="role"
                    value={formData.role}
                    onChange={handleInputChange}
                    required
                >
                    <option value="moderator">Moderator</option>
                    <option value="support">Support</option>
                </select>
                <button type="submit">Register Admin</button>
            </form>
        </div>
    );
};

export default AdminRegisterPage;
