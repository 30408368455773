import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import WelcomeSplash from './pages/WelcomeSplash';
import AdminLoginPage from './pages/AdminLoginPage';
import AdminDashboard from './pages/AdminDashboard';
import ChallengeSystem from './pages/ChallengeSystem';
import UserManagement from './pages/UserManagement';
import BetManagement from './pages/BetManagement';
import TransactionManagement from './pages/TransactionManagement';
import LeaderboardManagement from './pages/LeaderboardManagement';
import SystemSettings from './pages/SystemSettings';
import ReportsAnalytics from './pages/ReportsAnalytics';
import AdminRegisterPage from './pages/AdminRegisterPage';
import AddUser from './pages/AddUser';
import PaymentMethods from './pages/PaymentMethods';
import CreditUser from './pages/CreditUser';
import DebitUser from './pages/DebitUser';
import TeamManagement from './pages/TeamManagement';
import UserLogin from './pages/UserLogin';
import UserRegistration from './pages/UserRegistration';
import UserDashboard from './pages/UserDashboard';
import JoinChallenge from './pages/JoinChallenge';
import JoinChallenge2 from './pages/JoinChallenge2';
import ViewBets from './pages/ViewBets';
import IncomingBets from './pages/IncomingBets';
import Profile from './pages/Profile';
import AcceptedBets from './pages/AcceptedBets';
import UserLayout from './components/UserLayout';
import ChallengeManagement from './pages/ChallengeManagement';
import CreditChallenge from './pages/CreditChallenge';

function App() {
  return (
    <Router basename="/">
      <div className="App">
        <Routes>
          <Route path="/" element={<WelcomeSplash />} />
          <Route path="/admin">
            <Route path="login" element={<AdminLoginPage />} />
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="challenge-system" element={<ChallengeSystem />} />
            <Route path="users" element={<UserManagement />} />
            <Route path="bets" element={<BetManagement />} />
            <Route path="transactions" element={<TransactionManagement />} />
            <Route path="leaderboard" element={<LeaderboardManagement />} />
            <Route path="settings" element={<SystemSettings />} />
            <Route path="reports" element={<ReportsAnalytics />} />
            <Route path="register" element={<AdminRegisterPage />} />
            <Route path="add-user" element={<AddUser />} />
            <Route path="team-management" element={<TeamManagement />} />
            <Route path="credit-user" element={<CreditUser />} />
            <Route path="challenge-management" element={<ChallengeManagement />} />
            <Route path="credit-challenge" element={<CreditChallenge />} />
          </Route>
          <Route path="/user">
            <Route path="login" element={<UserLogin />} />
            <Route path="register" element={<UserRegistration />} />
            <Route path="dashboard" element={
              <UserLayout>
                <UserDashboard />
              </UserLayout>
            } />
          </Route>
          <Route path="/join-challenge/:challengeId" element={
            <UserLayout>
              <JoinChallenge />
            </UserLayout>
          } />
          <Route path="/join-challenge2/:challengeId/:betId/:uniqueCode/:user1Id" element={
            <UserLayout>
              <JoinChallenge2 />
            </UserLayout>
          } />
          <Route path="/view-bets" element={
            <UserLayout>
              <ViewBets />
            </UserLayout>
          } />
          <Route path="/incoming-bets" element={
            <UserLayout>
              <IncomingBets />
            </UserLayout>
          } />
          <Route path="/profile/:username" element={
            <UserLayout>
              <Profile />
            </UserLayout>
          } />
          <Route path="/accepted-bets" element={
            <UserLayout>
              <AcceptedBets />
            </UserLayout>
          } />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
