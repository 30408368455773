import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import './UserDashboard.css';

const API_BASE_URL = '/backend';

function UserDashboard() {
    const [user, setUser] = useState({});
    const [greeting, setGreeting] = useState('');
    const [recentBets, setRecentBets] = useState([]);
    const [recentChallenges, setRecentChallenges] = useState([]);
    const [leaderboard, setLeaderboard] = useState([]);
    const [analytics, setAnalytics] = useState({});
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        fetchUserData();
        fetchUserBets();
        fetchRecentChallenges();
        fetchLeaderboard();
        fetchAnalytics();
        fetchTeams();
        setGreeting(getGreeting());
    }, []);

    const fetchUserData = async () => {
        try {
            const userId = localStorage.getItem('userId');
            const response = await axios.get(`${API_BASE_URL}/handlers/user_data.php?id=${userId}`);
            if (response.data.success) {
                setUser(response.data.user);
            } else {
                console.error('Failed to fetch user data');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const fetchUserBets = async () => {
        try {
            const userId = localStorage.getItem('userId');
            const response = await axios.get(`${API_BASE_URL}/handlers/get_user_bets.php?userId=${userId}`);
            if (response.data.success) {
                setRecentBets(response.data.bets);
            } else {
                console.error('Failed to fetch user bets:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching user bets:', error);
        }
    };

    const fetchRecentChallenges = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/recent_challenges.php`);
            if (response.data.success) {
                const challengesWithDate = response.data.challenges.map(challenge => ({
                    ...challenge,
                    end_time: new Date(challenge.end_time)
                }));
                setRecentChallenges(challengesWithDate);
            } else {
                console.error('Failed to fetch recent challenges:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching recent challenges:', error);
        }
    };

    const fetchLeaderboard = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/leaderboard.php`);
            if (response.data.success) {
                setLeaderboard(response.data.leaderboard);
            } else {
                console.error('Failed to fetch leaderboard');
            }
        } catch (error) {
            console.error('Error fetching leaderboard:', error);
        }
    };

    const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span className="status-badge expired">Expired</span>;
        }
        return (
            <span>
                {days}d:{hours}h:{minutes}m:{seconds}s
            </span>
        );
    };
    
    const fetchAnalytics = async () => {
        try {
            const userId = localStorage.getItem('userId');
            const response = await axios.get(`${API_BASE_URL}/handlers/user_analytics.php?id=${userId}`);
            if (response.data.success) {
                const analyticsData = response.data.analytics;
                setAnalytics({
                    labels: ['Wins', 'Losses', 'Deposits', 'Withdrawals'],
                    datasets: [{
                        label: 'User Analytics',
                        data: [
                            analyticsData.wins,
                            analyticsData.losses,
                            analyticsData.total_deposits,
                            analyticsData.total_withdrawals
                        ],
                        backgroundColor: [
                            'rgba(75, 192, 192, 0.6)',
                            'rgba(255, 99, 132, 0.6)',
                            'rgba(54, 162, 235, 0.6)',
                            'rgba(255, 206, 86, 0.6)'
                        ],
                        borderColor: [
                            'rgba(75, 192, 192, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)'
                        ],
                        borderWidth: 1
                    }]
                });
            } else {
                console.error('Failed to fetch analytics');
            }
        } catch (error) {
            console.error('Error fetching analytics:', error);
        }
    };

    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
            if (response.data.status === 200) {
                setTeams(response.data.data);
            } else {
                console.error('Failed to fetch teams');
            }
        } catch (err) {
            console.error('Error fetching teams:', err);
        }
    };

    const getTeamLogo = (teamName) => {
        const team = teams.find(team => team.name === teamName);
        return team ? `${API_BASE_URL}/${team.logo}` : '';
    };

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) return 'Good morning';
        if (hour < 18) return 'Good afternoon';
        return 'Good evening';
    };

    return (
        <div className="user-dashboard">
            <h1>{greeting}, {user.username}!</h1>
            <div className="account-balance">
                <h2>Account Balance: {user.balance} FanCoins</h2>
                <button>Deposit FanCoins</button>
                <button>Withdraw FanCoins</button>
            </div>
            <div className="recent-bets">
                <h2>Recent Bets</h2>
                <div className="bets-list">
                    {recentBets.map(bet => (
                        <div key={bet.bet_id} className="bet-card">
                            <div className="bet-header">
                                <span className={`bet-status ${bet.bet_status}`}>
                                    {bet.bet_status}
                                </span>
                                <span className="bet-reference">
                                    REF: {bet.unique_code?.toUpperCase() || `${bet.bet_id}DNRBKCC`}
                                </span>
                            </div>
                            
                            <div className="potential-returns">
                                <div className="return-item win">Win: {bet.potential_return_win_user1} FC</div>
                                <div className="return-item draw">Draw: {bet.potential_return_draw_user1} FC</div>
                                <div className="return-item loss">Loss: {bet.potential_return_loss_user1} FC</div>
                            </div>

                            <div className="bet-matchup">
                                <div className="bet-team">
                                    <img src={getTeamLogo(bet.team_a)} alt={bet.team_a} className="team-logo-medium" />
                                    <div className="team-details">
                                        <span className="team-name">{bet.team_a}</span>
                                        <span className="username">
                                            {bet.bet_choice_user1 === 'team_a_win' ? bet.user1_name : bet.user2_name}
                                        </span>
                                        <span className="bet-amount">
                                            {bet.bet_choice_user1 === 'team_a_win' ? bet.amount_user1 : bet.amount_user2} FC
                                        </span>
                                    </div>
                                </div>

                                <div className="vs-badge">VS</div>

                                <div className="bet-team">
                                    <img src={getTeamLogo(bet.team_b)} alt={bet.team_b} className="team-logo-medium" />
                                    <div className="team-details">
                                        <span className="team-name">{bet.team_b}</span>
                                        <span className="username">
                                            {bet.bet_choice_user1 === 'team_b_win' ? bet.user1_name : bet.user2_name}
                                        </span>
                                        <span className="bet-amount">
                                            {bet.bet_choice_user1 === 'team_b_win' ? bet.amount_user1 : bet.amount_user2} FC
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="recent-challenges-container">
                <h2>Recent Challenges</h2>
                <div className="challenges-list">
                    <table>
                        <thead>
                            <tr>
                                <th>Teams</th>
                                <th>Odds</th>
                                <th>Goals</th>
                                <th>Date</th>
                                <th>Time Left</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {recentChallenges.map(challenge => (
                                <tr key={challenge.challenge_id}>
                                    <td>
                                        <div className="teams-compact">
                                            <div className="team-info">
                                                <img src={getTeamLogo(challenge.team_a)} alt={challenge.team_a} className="team-logo-small" />
                                                <span className="team-name-compact">{challenge.team_a}</span>
                                            </div>
                                            <span className="vs-small">vs</span>
                                            <div className="team-info">
                                                <img src={getTeamLogo(challenge.team_b)} alt={challenge.team_b} className="team-logo-small" />
                                                <span className="team-name-compact">{challenge.team_b}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="odds-compact">{challenge.odds_team_a} - {challenge.odds_team_b}</td>
                                    <td className="goals-compact">+{challenge.team_a_goal_advantage} / +{challenge.team_b_goal_advantage}</td>
                                    <td className="date-compact">{new Date(challenge.match_date).toLocaleDateString()}</td>
                                    <td className="countdown-compact">
                                        <Countdown date={challenge.end_time} renderer={CountdownRenderer} />
                                    </td>
                                    <td>
                                        <Link to={`/join-challenge/${challenge.challenge_id}`} className="place-bet-button-small">
                                            Bet
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="leaderboard">
                <h2>Leaderboard</h2>
                <ul>
                    {leaderboard.map(user => (
                        <li key={user.user_id}>
                            {user.username} - Points: {user.points}
                            <button>View Profile</button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="analytics">
                <h2>Your Analytics</h2>
                {analytics.datasets && <Line data={analytics} />}
            </div>
            <button className="place-bet-button">Place a Bet</button>
            <button className="profile-management">Profile Management</button>
        </div>
    );
}

export default UserDashboard;