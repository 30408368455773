import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './JoinChallenge.css';

const API_BASE_URL = '/backend';

function JoinChallenge2() {
  const { challengeId, betId, uniqueCode, user1Id } = useParams();
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');

  const [challenge, setChallenge] = useState(null);
  const [bet, setBet] = useState(null);
  const [teams, setTeams] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [potentialReturns, setPotentialReturns] = useState({
    win: 0,
    draw: 0,
    loss: 0
  });

  const fetchChallenge = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/get_challenges.php?id=${challengeId}`);
      if (response.data.success) {
        setChallenge(response.data.challenge);
      }
    } catch (error) {
      console.error('Error fetching challenge:', error);
    }
  }, [challengeId]);

  const fetchBetDetails = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/get_bet_details.php?betId=${betId}&uniqueCode=${uniqueCode}`);
      if (response.data.success) {
        setBet(response.data.bet);
      }
    } catch (error) {
      console.error('Error fetching bet details:', error);
      setError('Error loading bet details');
    }
  }, [betId, uniqueCode]);

  const fetchTeams = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
      if (response.data.status === 200) {
        setTeams(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  }, []);

  const calculatePotentialReturns = useCallback(() => {
    if (!bet || !challenge) return;
    
    const amount = parseFloat(bet.amount_user1);
    const drawOdds = 0.9;
    const lossOdds = 0.2;
    const winOdds = bet.bet_choice_user1 === 'team_a_win' ? challenge.odds_team_b : challenge.odds_team_a;

    setPotentialReturns({
      win: (amount * winOdds).toFixed(2),
      draw: (amount * drawOdds).toFixed(2),
      loss: (amount * lossOdds).toFixed(2)
    });
  }, [bet, challenge]);

  useEffect(() => {
    const fetchData = async () => {
      if (!userId) {
        navigate('/user/login');
        return;
      }

      if (parseInt(user1Id) === parseInt(userId)) {
        setError("You cannot bet against yourself!");
        setTimeout(() => {
          navigate('/view-bets');
        }, 3000);
        return;
      }

      try {
        await Promise.all([
          fetchChallenge(),
          fetchBetDetails(),
          fetchTeams()
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error loading challenge details');
      }
    };

    fetchData();
  }, [userId, user1Id, navigate, fetchChallenge, fetchBetDetails, fetchTeams]);

  useEffect(() => {
    if (bet && challenge) {
      calculatePotentialReturns();
    }
  }, [bet, challenge, calculatePotentialReturns]);

  const getTeamLogo = (teamName) => {
    const team = teams.find(team => team.name === teamName);
    return team ? `${API_BASE_URL}/${team.logo}` : '';
  };
    const handleAcceptBet = async () => {
      try {
        const response = await axios.post(`${API_BASE_URL}/handlers/accept_bet.php`, {
          betId,
          userId,
          amount: bet.amount_user1,
          user1_id: bet.user1_id,
          team1_id: bet.bet_choice_user1 === 'team_a_win' ? challenge.team_a_id : challenge.team_b_id,
          team2_id: bet.bet_choice_user1 === 'team_a_win' ? challenge.team_b_id : challenge.team_a_id,
          odds_user2: bet.bet_choice_user1 === 'team_a_win' ? challenge.odds_team_b : challenge.odds_team_a,
          potential_return_win_user2: potentialReturns.win,
          potential_return_draw_user2: potentialReturns.draw,
          potential_return_loss_user2: potentialReturns.loss,
          potential_return_user2: potentialReturns.win
        });

        if (response.data.success) {
          setSuccess('Bet accepted successfully! Redirecting...');
          setError(null); // Clear any previous errors
          setTimeout(() => navigate('/accepted-bets'), 2000);
        } else {
          // If response.data.success is false, display the error message
          setError(response.data.message || 'Failed to accept bet');
        }
      } catch (error) {
        console.error('Error accepting bet:', error);
        // Handle network errors or unexpected responses
        setError('An error occurred while placing the bet.');
      }
    };
  const user2Team = bet?.bet_choice_user1 === 'team_a_win' ? challenge?.team_b : challenge?.team_a;
  const user2Odds = bet?.bet_choice_user1 === 'team_a_win' ? challenge?.odds_team_b : challenge?.odds_team_a;

  return (
    <div className="join-challenge">
      <h1>Accept Challenge</h1>
      {error && (
        <div className="error-message" style={{ 
          fontSize: '18px', 
          textAlign: 'center',
          padding: '20px',
          marginBottom: '20px'
        }}>
          {error}
        </div>
      )}
      {success && <div className="success-message">{success}</div>}

      {challenge && bet && (
        <>
          <div className="challenge-details">
            <div className="team-info">
              <img src={getTeamLogo(challenge.team_a)} alt={challenge.team_a} className="team-logo" />
              <span>{challenge.team_a}</span>
              {bet.bet_choice_user1 === 'team_a_win' && <span className="taken-badge">Taken</span>}
            </div>
            <span>VS</span>
            <div className="team-info">
              <img src={getTeamLogo(challenge.team_b)} alt={challenge.team_b} className="team-logo" />
              <span>{challenge.team_b}</span>
              {bet.bet_choice_user1 === 'team_b_win' && <span className="taken-badge">Taken</span>}
            </div>
          </div>

          <div className="bet-details">
            <h3>Your Team: {user2Team}</h3>
            <p><strong>Bet Amount:</strong> {bet.amount_user1} FanCoins</p>
            <p><strong>Your Odds:</strong> {user2Odds}</p>

            <div className="potential-returns">
              <p>Expected Returns</p>
              <div className="return-row">
                <div className="return-item return-win">
                  <div>Win</div>
                  <div>{potentialReturns.win} FanCoins</div>
                </div>
                <div className="return-item return-draw">
                  <div>Draw</div>
                  <div>{potentialReturns.draw} FanCoins</div>
                </div>
                <div className="return-item return-loss">
                  <div>+Loss Return</div>
                  <div>{potentialReturns.loss} FanCoins</div>
                </div>
              </div>
            </div>

            <button
              onClick={handleAcceptBet}
              className="accept-button"
              style={{
                width: '100%',
                padding: '15px',
                fontSize: '16px',
                fontWeight: 'bold',
                marginTop: '20px',
                backgroundColor: '#2c5f2d',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              Accept Challenge
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default JoinChallenge2;