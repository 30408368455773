import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Countdown from 'react-countdown';
import './ChallengeManagement.css';

const API_BASE_URL = '/backend';

function ChallengeManagement() {
    const [challenges, setChallenges] = useState([]);
    const [teams, setTeams] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [selectedChallenge, setSelectedChallenge] = useState(null);
    const [editingChallenge, setEditingChallenge] = useState({
        team_a: '',
        team_b: '',
        odds_team_a: 1.80,
        odds_team_b: 1.80,
        start_time: '',
        end_time: '',
        match_date: '',
        status: 'Open'
    });

    useEffect(() => {
        fetchChallenges();
        fetchTeams();
    }, []);

    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
            if (response.data.status === 200) {
                setTeams(response.data.data);
            }
        } catch (err) {
            console.error('Error fetching teams:', err);
        }
    };

    const fetchChallenges = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/challenge_management.php`);
            if (response.data.success) {
                setChallenges(response.data.challenges);
            }
        } catch (err) {
            setError('Failed to fetch challenges');
        }
    };

    const getTeamLogo = (teamName) => {
        const team = teams.find(team => team.name === teamName);
        return team ? `${API_BASE_URL}/${team.logo}` : '';
    };

    const handleEdit = (challenge) => {
        setEditingChallenge(challenge);
        setShowEditModal(true);
    };

    const handleUpdate = async () => {
        try {
            const response = await axios.post(
                `${API_BASE_URL}/handlers/challenge_management.php`,
                editingChallenge
            );
            
            if (response.data.success) {
                setSuccess('Challenge updated successfully!');
                fetchChallenges();
                setShowEditModal(false);
            } else {
                setError(response.data.message || 'Failed to update challenge');
            }
        } catch (err) {
            setError('Failed to update challenge');
            console.error('Update error:', err);
        }
    };

    return (
        <div className="layout">
            <Sidebar />
            <div className="main-content">
                <Header />
                <div className="challenge-management">
                    <h1>Challenge Management</h1>
                    {error && <div className="error-message">{error}</div>}
                    {success && <div className="success-message">{success}</div>}
                    
                    <div className="challenges-list">
                        <table>
                            <thead>
                                <tr>
                                    <th>Teams</th>
                                    <th>Odds</th>
                                    <th>Match Date</th>
                                    <th>End Time</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {challenges.map((challenge) => (
                                    <tr key={challenge.challenge_id}>
                                        <td>
                                            <div className="teams-container">
                                                <div className="team-info">
                                                    <img src={getTeamLogo(challenge.team_a)} alt={challenge.team_a} className="team-logo" />
                                                    <span>{challenge.team_a}</span>
                                                </div>
                                                <div className="vs">VS</div>
                                                <div className="team-info">
                                                    <img src={getTeamLogo(challenge.team_b)} alt={challenge.team_b} className="team-logo" />
                                                    <span>{challenge.team_b}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{challenge.odds_team_a} - {challenge.odds_team_b}</td>
                                        <td>{new Date(challenge.match_date).toLocaleString()}</td>
                                        <td>
                                            <Countdown 
                                                date={new Date(challenge.end_time)}
                                                renderer={CountdownRenderer}
                                            />
                                        </td>
                                        <td>
                                            <span className={`status-badge ${challenge.status}`}>
                                                {challenge.status}
                                            </span>
                                        </td>
                                        <td>
                                            <button onClick={() => handleEdit(challenge)}>Edit</button>
                                            <button onClick={() => {
                                                setSelectedChallenge(challenge);
                                                setShowPreviewModal(true);
                                            }}>Preview</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {showPreviewModal && selectedChallenge && (
                        <PreviewModal 
                            challenge={selectedChallenge}
                            onClose={() => setShowPreviewModal(false)}
                            teams={teams}
                            getTeamLogo={getTeamLogo}
                        />
                    )}

                    {showEditModal && (
                        <EditModal 
                            challenge={editingChallenge}
                            onClose={() => setShowEditModal(false)}
                            onUpdate={handleUpdate}
                            onChange={(field, value) => setEditingChallenge(prev => ({
                                ...prev,
                                [field]: value
                            }))}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <span className="status-badge expired">Expired</span>;
    }
    return (
        <div className="countdown-timer">
            <span>{days}d </span>
            <span>{hours}h </span>
            <span>{minutes}m </span>
            <span>{seconds}s</span>
        </div>
    );
};

const PreviewModal = ({ challenge, onClose, teams, getTeamLogo }) => (
    <div className="modal-overlay">
        <div className="preview-modal">
            <button className="close-button" onClick={onClose}>×</button>
            <div className="teams-container">
                <div className="team-column">
                    <img src={getTeamLogo(challenge.team_a)} alt={challenge.team_a} className="team-logo-large" />
                    <h3>{challenge.team_a}</h3>
                    <p className="odds">Odds: {challenge.odds_team_a}</p>
                </div>
                <div className="vs-container">
                    <span className="vs-badge">VS</span>
                </div>
                <div className="team-column">
                    <img src={getTeamLogo(challenge.team_b)} alt={challenge.team_b} className="team-logo-large" />
                    <h3>{challenge.team_b}</h3>
                    <p className="odds">Odds: {challenge.odds_team_b}</p>
                </div>
            </div>
            <div className="match-details">
                <div className="detail-row">
                    <div className="detail-item">
                        <span className="label">Start Time</span>
                        <span className="value">{new Date(challenge.start_time).toLocaleString()}</span>
                    </div>
                    <div className="detail-item">
                        <span className="label">End Time</span>
                        <span className="value">{new Date(challenge.end_time).toLocaleString()}</span>
                    </div>
                </div>
                <div className="detail-row">
                    <div className="detail-item">
                        <span className="label">Match Date</span>
                        <span className="value">{new Date(challenge.match_date).toLocaleString()}</span>
                    </div>
                    <div className="detail-item">
                        <span className="label">Status</span>
                        <span className="value status-badge">{challenge.status}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const EditModal = ({ challenge, onClose, onUpdate, onChange }) => (
    <div className="modal-overlay">
        <div className="edit-modal">
            <button className="close-button" onClick={onClose}>×</button>
            <h2>Edit Challenge</h2>
            <div className="form-group">
                <label>Odds Team A</label>
                <input 
                    type="number" 
                    step="0.01"
                    value={challenge.odds_team_a}
                    onChange={(e) => onChange('odds_team_a', e.target.value)}
                />
            </div>
            <div className="form-group">
                <label>Odds Team B</label>
                <input 
                    type="number"
                    step="0.01"
                    value={challenge.odds_team_b}
                    onChange={(e) => onChange('odds_team_b', e.target.value)}
                />
            </div>
            <div className="form-group">
                <label>Start Time</label>
                <input 
                    type="datetime-local"
                    value={challenge.start_time.slice(0, 16)}
                    onChange={(e) => onChange('start_time', e.target.value)}
                />
            </div>
            <div className="form-group">
                <label>End Time</label>
                <input 
                    type="datetime-local"
                    value={challenge.end_time.slice(0, 16)}
                    onChange={(e) => onChange('end_time', e.target.value)}
                />
            </div>
            <div className="form-group">
                <label>Match Date</label>
                <input 
                    type="datetime-local"
                    value={challenge.match_date.slice(0, 16)}
                    onChange={(e) => onChange('match_date', e.target.value)}
                />
            </div>
            <div className="form-group">
                <label>Status</label>
                <select 
                    value={challenge.status}
                    onChange={(e) => onChange('status', e.target.value)}
                >
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                    <option value="Settled">Settled</option>
                </select>
            </div>
            <div className="button-group">
                <button onClick={onUpdate}>Update Challenge</button>
                <button onClick={onClose}>Cancel</button>
            </div>
        </div>
    </div>
);

export default ChallengeManagement;