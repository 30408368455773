import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './UserLayout.css';

function UserLayout({ children, username }) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('userToken');
    navigate('/user/login');
  };

  return (
    <div className="user-layout">
      <header className="user-header">
        <div className="logo">
          <Link to="/user/dashboard">FanBet247</Link>
        </div>
        <nav className="user-nav">
          <ul>
            <li className={location.pathname.includes('/bets') ? 'active' : ''}>
              <Link to="/view-bets">My Bets</Link>
              <div className="submenu">
                <Link to="/view-bets">Outgoing Bets</Link>
                <Link to="/incoming-bets">Incoming Bets</Link>
                <Link to="/accepted-bets">Accepted Bets</Link>
              </div>
            </li>
            <li className={location.pathname === '/payment-history' ? 'active' : ''}>
              <Link to="/payment-history">Payment History</Link>
            </li>
            <li className={location.pathname === '/leaderboard' ? 'active' : ''}>
              <Link to="/leaderboard">Leaderboard</Link>
            </li>
            <li className={location.pathname.includes('/profile') ? 'active' : ''}>
              <Link to={`/profile/${localStorage.getItem('username')}`}>My Profile</Link>
            </li>
            <li className={location.pathname === '/change-password' ? 'active' : ''}>
              <Link to="/change-password">Change Password</Link>
            </li>
            <li className={location.pathname === '/deposit' ? 'active' : ''}>
              <Link to="/deposit">Deposit</Link>
            </li>
            <li className={location.pathname === '/withdraw' ? 'active' : ''}>
              <Link to="/withdraw">Withdraw</Link>
            </li>
          </ul>
        </nav>
        <div className="logout-container">
          <button onClick={handleLogout} className="logout-button">Logout</button>
        </div>
      </header>

      <main className="user-content">
        {children}
      </main>

      <footer className="user-footer">
        <p>© 2024 FanBet247. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default UserLayout;