import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './JoinChallenge.css';

const API_BASE_URL = '/backend';

function JoinChallenge() {
  const { challengeId } = useParams();
  const navigate = useNavigate();
  const [challenge, setChallenge] = useState(null);
  const [selectedOutcome, setSelectedOutcome] = useState('');
  const [betAmount, setBetAmount] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [teams, setTeams] = useState([]);
  const [potentialReturns, setPotentialReturns] = useState({
    win: 0,
    draw: 0,
    loss: 0,
  });

  // Improved teams fetching with retry
  const fetchTeams = useCallback(async (retryCount = 0) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
      if (response.data.status === 200) {
        setTeams(response.data.data);
      }
    } catch (err) {
      console.error('Error fetching teams:', err);
      if (retryCount < 2) {
        setTimeout(() => fetchTeams(retryCount + 1), 1000);
      }
    }
  }, []);

  // Improved challenge fetching with retry and better response handling
  const fetchChallengeDetails = useCallback(async (retryCount = 0) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/get_challenges.php?id=${challengeId}`);
      
      // Handle both array and object responses
      if (Array.isArray(response.data)) {
        const challengeData = response.data.find(c => c.challenge_id === challengeId);
        if (challengeData) {
          setChallenge(challengeData);
          setErrorMessage(null);
          return;
        }
      } 
      // Handle object response
      else if (response.data.success && response.data.challenge) {
        setChallenge(response.data.challenge);
        setErrorMessage(null);
        return;
      }
      
      // If we reach here, no challenge was found
      if (retryCount < 2) {
        setTimeout(() => fetchChallengeDetails(retryCount + 1), 1000);
      } else {
        setErrorMessage('Challenge not found. Please try again.');
      }
    } catch (error) {
      console.error('Error fetching challenge details:', error);
      if (retryCount < 2) {
        setTimeout(() => fetchChallengeDetails(retryCount + 1), 1000);
      } else {
        setErrorMessage('Failed to load challenge details. Please refresh the page.');
      }
    }
  }, [challengeId]);

  const calculatePotentialReturns = useCallback(() => {
    if (!betAmount || !challenge) return;
    const amount = parseFloat(betAmount);
    let winOdds = 0;
    
    try {
      if (selectedOutcome === 'team_a_win') {
        winOdds = parseFloat(challenge.odds_team_a) || 0;
      } else if (selectedOutcome === 'team_b_win') {
        winOdds = parseFloat(challenge.odds_team_b) || 0;
      } else {
        return;
      }

      const drawOdds = 0.9;
      const lossOdds = 0.2;

      setPotentialReturns({
        win: (amount * winOdds).toFixed(2),
        draw: (amount * drawOdds).toFixed(2),
        loss: (amount * lossOdds).toFixed(2),
      });
    } catch (err) {
      console.error('Error calculating returns:', err);
    }
  }, [betAmount, selectedOutcome, challenge]);

  useEffect(() => {
    if (challengeId) {
      fetchChallengeDetails();
      fetchTeams();
    }
  }, [fetchChallengeDetails, fetchTeams, challengeId]);

  useEffect(() => {
    calculatePotentialReturns();
  }, [calculatePotentialReturns]);

  const getTeamLogo = useCallback((teamName) => {
    const team = teams.find(t => t.name === teamName);
    return team ? `${API_BASE_URL}/${team.logo}` : '';
  }, [teams]);

  const handleSubmitBet = async (event) => {
    event.preventDefault();
    setErrorMessage(null);

    if (!selectedOutcome || !betAmount) {
      setErrorMessage('Please select an outcome and enter a bet amount.');
      return;
    }

    try {
      const userId = localStorage.getItem('userId');
      const odds = selectedOutcome === 'team_a_win' ? challenge.odds_team_a : challenge.odds_team_b;
      const amount = parseFloat(betAmount);
      const winAmount = amount * odds;
      const drawAmount = amount * 0.9;
      const lossAmount = amount * 0.2;

      const response = await axios.post(`${API_BASE_URL}/handlers/place_bet.php`, {
        challengeId,
        userId,
        outcome: selectedOutcome,
        amount: betAmount,
        odds,
        potential_return_user1: winAmount,
        potential_return_win_user1: winAmount,
        potential_return_draw_user1: drawAmount,
        potential_return_loss_user1: lossAmount
      });

      if (response.data.success) {
        setSuccessMessage('Bet placed successfully! Redirecting to your bets...');
        setTimeout(() => navigate('/view-bets'), 3000);
      } else {
        throw new Error(response.data.message || 'Failed to place bet');
      }
    } catch (error) {
      console.error('Error placing bet:', error);
      setErrorMessage(error.response?.data?.message || 'An error occurred while placing your bet.');
    }
  };

  if (!challenge && !errorMessage) {
    return <div className="join-challenge"><p>Loading challenge details...</p></div>;
  }

  return (
    <div className="join-challenge">
      <h1>Join Challenge</h1>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {challenge && (
        <div>
          <div className="challenge-details">
            <div className="team-info">
              <img src={getTeamLogo(challenge.team_a)} alt={challenge.team_a} className="team-logo" />
              <span>{challenge.team_a}</span>
            </div>
            <span>VS</span>
            <div className="team-info">
              <img src={getTeamLogo(challenge.team_b)} alt={challenge.team_b} className="team-logo" />
              <span>{challenge.team_b}</span>
            </div>
          </div>
          <div className="additional-details">
            <p><strong>Odds:</strong> {challenge.odds_team_a} - {challenge.odds_team_b}</p>
            <p><strong>Goal Advantage:</strong> {challenge.team_a_goal_advantage} - {challenge.team_b_goal_advantage}</p>
            <p><strong>Match Date:</strong> {new Date(challenge.match_date).toLocaleString()}</p>
          </div>
          <form className="bet-form" onSubmit={handleSubmitBet}>
            <div>
              <label htmlFor="outcome">Select Team:</label>
              <select id="outcome" value={selectedOutcome} onChange={(e) => setSelectedOutcome(e.target.value)}>
                <option value="">-- Select --</option>
                <option value="team_a_win">Home Win ({challenge.team_a})</option>
                <option value="team_b_win">Away Win ({challenge.team_b})</option>
              </select>
            </div>
            <div>
              <label htmlFor="amount">Bet Amount (FanCoins):</label>
              <input
                type="number"
                id="amount"
                value={betAmount}
                onChange={(e) => setBetAmount(e.target.value)}
              />
            </div>
            {betAmount && selectedOutcome && (
              <div className="potential-returns">
                <p>Expected Returns</p>
                <div className="return-row">
                  <div className="return-item return-win">
                    <div>Win</div>
                    <div>{potentialReturns.win} FanCoins</div>
                  </div>
                  <div className="return-item return-draw">
                    <div>Draw</div>
                    <div>{potentialReturns.draw} FanCoins</div>
                  </div>
                  <div className="return-item return-loss">
                    <div>+Loss Return</div>
                    <div>{potentialReturns.loss} FanCoins</div>
                  </div>
                </div>
              </div>
            )}
            {successMessage && <div className="success-message">{successMessage}</div>}
            <button type="submit">Place Bet</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default JoinChallenge;