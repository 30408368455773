import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ViewBets.css';

const API_BASE_URL = '/backend';

function AcceptedBets() {
  const [acceptedBets, setAcceptedBets] = useState([]);
  const [teams, setTeams] = useState([]);
  const [showBetDetailsModal, setShowBetDetailsModal] = useState(false);
  const [selectedBet, setSelectedBet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();

  const fetchBets = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/get_bets.php?userId=${userId}`);
      if (response.data.success) {
        // Filter bets where current user is user2_id
        const acceptedBets = response.data.bets.filter(bet => bet.user2_id === userId);
        setAcceptedBets(acceptedBets || []);
      } else {
        setError('Failed to fetch accepted bets');
        console.error("Error fetching bets:", response.data.message || "Unknown error");
      }
    } catch (error) {
      console.error('Error fetching bets:', error);
      setError('An error occurred while fetching accepted bets.');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  const fetchTeams = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
      if (response.data.status === 200) {
        setTeams(response.data.data || []);
      } else {
        setError('Failed to fetch teams');
        console.error('Failed to fetch teams:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
      setError('An error occurred while fetching teams.');
    }
  }, []);

  useEffect(() => {
    if (!userId) {
      navigate('/user/login');
    } else {
      fetchBets();
      fetchTeams();
    }
  }, [userId, fetchBets, fetchTeams, navigate]);

  const getTeamLogo = (teamName) => {
    const team = teams.find(team => team.name === teamName);
    return team ? `${API_BASE_URL}/${team.logo}` : '';
  };

  const getReference = (bet) => {
    return (bet.unique_code || `${bet.bet_id}DNRBKCC`).toUpperCase();
  };

  const handleShowBetDetails = (bet) => {
    setSelectedBet(bet);
    setShowBetDetailsModal(true);
  };

  return (
    <div className="view-bets-container">
      <h2>Accepted Bets</h2>
      <table className="bets-table">
        <thead>
          <tr>
            <th>Ref</th>
            <th>From</th>
            <th>Team A</th>
            <th>Team B</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {acceptedBets.map(bet => (
            <tr key={bet.bet_id}>
              <td>
                <span className="reference" onClick={() => handleShowBetDetails(bet)}>
                  {getReference(bet)}
                </span>
              </td>
              <td>{bet.user1_name}</td>
              <td>
                <div className="team-info">
                  <img src={getTeamLogo(bet.team_a)} alt={bet.team_a} className="team-logo" />
                  <span>{bet.team_a}</span>
                  {bet.bet_choice_user2 === 'team_a_win' && <span className="pick-badge">Your Pick</span>}
                </div>
              </td>
              <td>
                <div className="team-info">
                  <img src={getTeamLogo(bet.team_b)} alt={bet.team_b} className="team-logo" />
                  <span>{bet.team_b}</span>
                  {bet.bet_choice_user2 === 'team_b_win' && <span className="pick-badge">Your Pick</span>}
                </div>
              </td>
              <td>{bet.amount_user2} FanCoins</td>
              <td>
                <span className={`status ${bet.bet_status}`}>
                  {bet.bet_status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showBetDetailsModal && selectedBet && (
        <div className="modal-overlay">
          <div className="bet-details-modal">
            <button className="close-button" onClick={() => setShowBetDetailsModal(false)}>×</button>
            
            <div className="modal-left">
              <h3 className="reference-title">Bet Reference: {getReference(selectedBet)}</h3>
              
              <div className="teams-match">
                <div className={`team-card user1`}>
                  <img src={getTeamLogo(selectedBet.team_a)} alt={selectedBet.team_a} />
                  <div className="team-name">{selectedBet.team_a}</div>
                  <div className="team-odds user1">Odds: {selectedBet.odds_team_a}</div>
                  <div className="team-user">{selectedBet.user1_name}</div>
                </div>
                
                <div className="vs-badge">VS</div>
                
                <div className={`team-card user2`}>
                  <img src={getTeamLogo(selectedBet.team_b)} alt={selectedBet.team_b} />
                  <div className="team-name">{selectedBet.team_b}</div>
                  <div className="team-odds user2">Odds: {selectedBet.odds_team_b}</div>
                  <div className="team-user">{selectedBet.username2}</div>
                </div>
              </div>
            </div>
                        <div className="modal-right">
              <div className="status-badge-large" data-status={selectedBet.bet_status}>
                {selectedBet.bet_status.toUpperCase()}
              </div>

              <div className="bet-details-grid">
                <div className="detail-item amount">
                  <div className="detail-label">Your Bet Amount</div>
                  <div className="detail-value amount">{selectedBet.amount_user2} FanCoins</div>
                </div>
                <div className="detail-item return">
                  <div className="detail-label">Your Potential Return</div>
                  <div className="detail-value return">{selectedBet.potential_return_win_user2} FanCoins</div>
                </div>
                <div className="detail-item">
                  <div className="detail-label">Opponent ({selectedBet.user1_name})</div>
                  <div className="detail-value">{selectedBet.amount_user1} FanCoins</div>
                </div>
                <div className="detail-item">
                  <div className="detail-label">Opponent Potential Return</div>
                  <div className="detail-value">{selectedBet.potential_return_win_user1} FanCoins</div>
                </div>
                <div className="detail-item">
                  <div className="detail-label">Created At</div>
                  <div className="detail-value">{new Date(selectedBet.created_at).toLocaleString()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AcceptedBets;