import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import './AddUser.css';

const API_BASE_URL = '/backend';

function AddUser() {
    const [teams, setTeams] = useState([]);
    const [newUser, setNewUser] = useState({
        username: '',
        full_name: '',
        email: '',
        password: '',
        favorite_team: '',
        balance: 0
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        fetchTeams();
    }, []);

    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
            setTeams(response.data.data || []);
        } catch (err) {
            setError('Failed to fetch teams');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            const response = await axios.post(`${API_BASE_URL}/handlers/add_user.php`, newUser);
            if (response.data.success) {
                setSuccess('User added successfully!');
                setNewUser({
                    username: '',
                    full_name: '',
                    email: '',
                    password: '',
                    favorite_team: '',
                    balance: 0
                });
            } else {
                setError(response.data.message || 'Failed to add user');
            }
        } catch (err) {
            setError('Failed to add user');
        }
    };

    return (
        <div className="layout">
            <Sidebar />
            <div className="main-content">
                <Header />
                <div className="add-user">
                    <h1>Add New User</h1>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Username:
                            <input
                                type="text"
                                name="username"
                                value={newUser.username}
                                onChange={handleInputChange}
                                placeholder="Username"
                                required
                            />
                        </label>
                        <label>
                            Full Name:
                            <input
                                type="text"
                                name="full_name"
                                value={newUser.full_name}
                                onChange={handleInputChange}
                                placeholder="Full Name"
                                required
                            />
                        </label>
                        <label>
                            Email:
                            <input
                                type="email"
                                name="email"
                                value={newUser.email}
                                onChange={handleInputChange}
                                placeholder="Email"
                                required
                            />
                        </label>
                        <label>
                            Password:
                            <input
                                type="password"
                                name="password"
                                value={newUser.password}
                                onChange={handleInputChange}
                                placeholder="Password"
                                required
                            />
                        </label>
                        <label>
                            Favorite Team:
                            <select
                                name="favorite_team"
                                value={newUser.favorite_team}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select Favorite Team</option>
                                {teams.map(team => (
                                    <option key={team.id} value={team.name}>{team.name}</option>
                                ))}
                            </select>
                        </label>
                        <label>
                            Initial Balance:
                            <input
                                type="number"
                                name="balance"
                                value={newUser.balance}
                                onChange={handleInputChange}
                                placeholder="Initial Balance"
                                required
                            />
                        </label>
                        <button type="submit">Add User</button>
                    </form>
                    {error && <div className="error-message">{error}</div>}
                    {success && <div className="success-message">{success}</div>}
                </div>
            </div>
        </div>
    );
}

export default AddUser;