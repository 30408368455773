import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import './BetManagement.css';

const API_BASE_URL = '/backend';

function BetManagement() {
    const [teams, setTeams] = useState([]);
    const [bets, setBets] = useState([]);

    useEffect(() => {
        fetchTeams();
        fetchAllBets();
    }, []);

    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
            if (response.data.status === 200) {
                setTeams(response.data.data);
            }
        } catch (err) {
            console.error('Error fetching teams:', err);
        }
    };

    const fetchAllBets = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/get_all_bets.php`);
            if (response.data.success) {
                setBets(response.data.bets.map(bet => ({
                    ...bet,
                    user1_pick: bet.bet_choice_user1 === 'team_a_win' ? bet.team_a : bet.team_b,
                    user2_pick: bet.bet_choice_user2 === 'team_a_win' ? bet.team_a : bet.team_b
                })));
            }
        } catch (err) {
            console.error('Error fetching bets:', err);
        }
    };

    const getTeamLogo = (teamName) => {
        const team = teams.find(team => team.name === teamName);
        return team ? `${API_BASE_URL}/${team.logo}` : '';
    };

    return (
        <div className="layout">
            <Sidebar />
            <div className="main-content">
                <Header />
                <div className="bet-management">
                    <h1>Bet Management</h1>
                    <div className="bets-list">
                        <h2>All System Bets</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Ref</th>
                                    <th>User 1</th>
                                    <th>Pick</th>
                                    <th>Amount</th>
                                    <th>User 2</th>
                                    <th>Pick</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Returns</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bets.map(bet => (
                                    <tr key={bet.bet_id} className={bet.bet_status}>
                                        <td>{bet.unique_code?.toUpperCase() || `${bet.bet_id}DNRBKCC`}</td>
                                        <td>{bet.user1_name}</td>
                                        <td>
                                            <div className="team-info">
                                                <img src={getTeamLogo(bet.user1_pick)} alt={bet.user1_pick} />
                                                <span>{bet.user1_pick}</span>
                                            </div>
                                        </td>
                                        <td>{bet.amount_user1} FC</td>
                                        <td>{bet.user2_name || 'Waiting'}</td>
                                        <td>
                                            {bet.user2_name && (
                                                <div className="team-info">
                                                    <img src={getTeamLogo(bet.user2_pick)} alt={bet.user2_pick} />
                                                    <span>{bet.user2_pick}</span>
                                                </div>
                                            )}
                                        </td>
                                        <td>{bet.amount_user2 || '-'} FC</td>
                                        <td>
                                            <span className={`status ${bet.bet_status}`}>
                                                {bet.bet_status}
                                            </span>
                                        </td>
                                        <td>
                                            <div className="returns-info">
                                                <div>Win: {bet.potential_return_win_user1} FC</div>
                                                <div>Draw: {bet.potential_return_draw_user1} FC</div>
                                                <div>Loss: {bet.potential_return_loss_user1} FC</div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BetManagement;