import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import './ChallengeSystem.css'; // Import the CSS file
import Countdown from 'react-countdown'; // Add this package
const API_BASE_URL = '/backend';

function ChallengeSystem() {
  const [challenges, setChallenges] = useState([]);
  const [teams, setTeams] = useState([]);
  const [newChallenge, setNewChallenge] = useState({
    team1: '',
    team2: '',
    odds1: 1,
    odds2: 1,
    goalAdvantage1: 0,
    goalAdvantage2: 0,
    startTime: '',
    endTime: '',
    matchTime: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
      setTeams(response.data.data);
    } catch (err) {
      console.error("Error fetching teams:", err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewChallenge(prev => ({
      ...prev,
      [name]: value 
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!newChallenge.team1 || !newChallenge.team2 || !newChallenge.odds1 || !newChallenge.odds2) {
      setError('Team names and odds are required.');
      return;
    }

    try {
      const formData = new FormData();

      // Find the selected team objects to get their full logo URLs
      const team1Data = teams.find(t => t.name === newChallenge.team1);
      const team2Data = teams.find(t => t.name === newChallenge.team2);

      // Append data to FormData, including full image URLs
      formData.append('team1', newChallenge.team1);
      formData.append('team2', newChallenge.team2);
      formData.append('odds1', newChallenge.odds1);
      formData.append('odds2', newChallenge.odds2);
      formData.append('goalAdvantage1', newChallenge.goalAdvantage1);
      formData.append('goalAdvantage2', newChallenge.goalAdvantage2);
      formData.append('startTime', newChallenge.startTime);
      formData.append('endTime', newChallenge.endTime);
      formData.append('matchTime', newChallenge.matchTime);
      formData.append('logo1', team1Data ? `${API_BASE_URL}/${team1Data.logo}` : ''); // Full URL
      formData.append('logo2', team2Data ? `${API_BASE_URL}/${team2Data.logo}` : ''); // Full URL

      const response = await axios.post(`${API_BASE_URL}/handlers/create_challenge.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (response.data.success) {
        setSuccess('Challenge created successfully!');
        setNewChallenge({
          team1: '',
          team2: '',
          odds1: 1,
          odds2: 1,
          goalAdvantage1: 0,
          goalAdvantage2: 0,
          startTime: '',
          endTime: '',
          matchTime: '',
        });
      } else {
        setError(response.data.message || 'Failed to create challenge');
      }
    } catch (err) {
      setError('Failed to create challenge');
    }
  };

  const handleDiscard = () => {
    setNewChallenge({
      team1: '', team2: '', odds1: 1, odds2: 1, 
      goalAdvantage1: 0, goalAdvantage2: 0,
      startTime: '', endTime: '', matchTime: '',
    });
    setError('');
    setSuccess('');
  };

  return (
    <div className="layout">
      <Sidebar />
      <div className="main-content">
        <Header />
        <div className="challenge-system">
          <h1>Create a New Challenge</h1>
          <div className="header-actions">
            <button onClick={handleSubmit}>Save Challenge</button>
            <button onClick={handleDiscard}>Discard Changes</button>
          </div>
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}
          <form onSubmit={handleSubmit} className="challenge-form">
            <div className="team-container">
              <div className="team-section team1">
                <h3>Team 1</h3>
                <label htmlFor="team1">Select Team 1:</label>
                <select id="team1" name="team1" value={newChallenge.team1} onChange={handleInputChange} required>
                  <option value="">Select a team</option>
                  {teams.map((team) => (
                    <option key={team.id} value={team.name}>
                      {team.name}
                    </option>
                  ))}
                </select>

                {newChallenge.team1 && (
                  <img
                    src={`${API_BASE_URL}/${teams.find(t => t.name === newChallenge.team1)?.logo}`}
                    alt="Team 1 Logo"
                    className="logo-preview"
                  />
                )}

                <label htmlFor="odds1">Odds for Team 1:</label>
                <input
                  type="number"
                  id="odds1"
                  name="odds1"
                  value={newChallenge.odds1}
                  onChange={handleInputChange}
                  required
                  step="0.01"
                  min="1"
                />
                <p className="odds-explanation">
                  User's bet x {newChallenge.odds1} = Potential winnings
                </p>

                <label htmlFor="goalAdvantage1">Goal Advantage for Team 1:</label>
                <input type="number" id="goalAdvantage1" name="goalAdvantage1" value={newChallenge.goalAdvantage1} onChange={handleInputChange} />
              </div>

              <div className="team-section team2">
                <h3>Team 2</h3>
                <label htmlFor="team2">Select Team 2:</label>
                <select id="team2" name="team2" value={newChallenge.team2} onChange={handleInputChange} required>
                  <option value="">Select a team</option>
                  {teams.map((team) => (
                    <option key={team.id} value={team.name}>
                      {team.name}
                    </option>
                  ))}
                </select>

                {newChallenge.team2 && (
                  <img
                    src={`${API_BASE_URL}/${teams.find(t => t.name === newChallenge.team2)?.logo}`}
                    alt="Team 2 Logo"
                    className="logo-preview"
                  />
                )}

                <label htmlFor="odds2">Odds for Team 2:</label>
                <input
                  type="number"
                  id="odds2"
                  name="odds2"
                  value={newChallenge.odds2}
                  onChange={handleInputChange}
                  required
                  step="0.01"
                  min="1"
                />
                <p className="odds-explanation">
                  User's bet x {newChallenge.odds2} = Potential winnings
                </p>

                <label htmlFor="goalAdvantage2">Goal Advantage for Team 2:</label>
                <input type="number" id="goalAdvantage2" name="goalAdvantage2" value={newChallenge.goalAdvantage2} onChange={handleInputChange} />
              </div>
            </div>

            <div className="time-section">
              <label htmlFor="startTime">Challenge Start Time:</label>
              <input type="datetime-local" id="startTime" name="startTime" value={newChallenge.startTime} onChange={handleInputChange} required />
            
              <label htmlFor="endTime">Challenge End Time:</label>
              <input type="datetime-local" id="endTime" name="endTime" value={newChallenge.endTime} onChange={handleInputChange} required />
            
              <label htmlFor="matchTime">Actual Match Time:</label>
              <input type="datetime-local" id="matchTime" name="matchTime" value={newChallenge.matchTime} onChange={handleInputChange} required />
            </div>
          </form>

          <div className="challenge-preview">
            <h2>Real-time Preview</h2>
            <p>{newChallenge.team1} vs {newChallenge.team2}</p>
            <p>Odds: {newChallenge.odds1} - {newChallenge.odds2}</p>
            <p>Goal Advantage: {newChallenge.goalAdvantage1} - {newChallenge.goalAdvantage2}</p>
            <p>Start: {newChallenge.startTime}</p>
            <p>End: {newChallenge.endTime}</p>
            <p>Match Time: {newChallenge.matchTime}</p>
            {newChallenge.team1 && (
              <img
                src={`${API_BASE_URL}/${teams.find(t => t.name === newChallenge.team1)?.logo}`}
                alt="Team 1 Logo"
                className="logo-preview"
              />
            )}
            {newChallenge.team2 && (
              <img
                src={`${API_BASE_URL}/${teams.find(t => t.name === newChallenge.team2)?.logo}`}
                alt="Team 2 Logo"
                className="logo-preview"
              />
            )}
          </div>
        
        </div>      </div>
    </div>
  );
}

export default ChallengeSystem;
