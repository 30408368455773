import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import './UserManagement.css';

const API_BASE_URL = '/backend';

function UserManagement() {
    const [users, setUsers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [editingUserId, setEditingUserId] = useState(null);
    const [editingUser, setEditingUser] = useState({
        username: '',
        full_name: '',
        email: '',
        favorite_team: '',
        balance: 0
    });

    useEffect(() => {
        fetchUsers();
        fetchTeams();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/user_management.php`);
            if (response.data.success) {
                setUsers(response.data.data || []);
            } else {
                setError(response.data.message || 'Failed to fetch users');
            }
        } catch (err) {
            setError('Failed to fetch users. Please check your network connection and try again.');
            console.error('Error fetching users:', err);
        }
    };

    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
            setTeams(response.data.data || []);
        } catch (err) {
            setError('Failed to fetch teams');
        }
    };

    const handleEdit = (user) => {
        setEditingUserId(user.user_id);
        setEditingUser(user);
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`${API_BASE_URL}/handlers/user_management.php?id=${editingUserId}`, editingUser);
            if (response.data.success) {
                setSuccess('User updated successfully!');
                fetchUsers();
                setEditingUserId(null);
            } else {
                setError(response.data.message || 'Failed to update user');
            }
        } catch (err) {
            setError('Failed to update user');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditingUser(prev => ({ ...prev, [name]: value }));
    };

    return (
        <div className="layout">
            <Sidebar />
            <div className="main-content">
                <Header />
                <div className="user-management">
                    <h1>User Management</h1>
                    {error && <div className="error-message">{error}</div>}
                    {success && <div className="success-message">{success}</div>}
                    <table>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Full Name</th>
                                <th>Email</th>
                                <th>Favorite Team</th>
                                <th>Balance</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(user => (
                                <tr key={user.user_id}>
                                    <td>{user.username}</td>
                                    <td>{user.full_name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.favorite_team}</td>
                                    <td>{user.balance}</td>
                                    <td>
                                        <button onClick={() => handleEdit(user)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {editingUserId && (
                        <div className="edit-user-form">
                            <h2>Edit User</h2>
                            <form onSubmit={handleUpdate}>
                                <input
                                    type="text"
                                    name="username"
                                    value={editingUser.username}
                                    onChange={handleInputChange}
                                    placeholder="Username"
                                    required
                                />
                                <input
                                    type="text"
                                    name="full_name"
                                    value={editingUser.full_name}
                                    onChange={handleInputChange}
                                    placeholder="Full Name"
                                    required
                                />
                                <input
                                    type="email"
                                    name="email"
                                    value={editingUser.email}
                                    onChange={handleInputChange}
                                    placeholder="Email"
                                    required
                                />
                                <select
                                    name="favorite_team"
                                    value={editingUser.favorite_team}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Favorite Team</option>
                                    {teams.map(team => (
                                        <option key={team.id} value={team.name}>{team.name}</option>
                                    ))}
                                </select>
                                <input
                                    type="number"
                                    name="balance"
                                    value={editingUser.balance}
                                    onChange={handleInputChange}
                                    placeholder="Balance"
                                    required
                                />
                                <button type="submit">Update User</button>
                                <button type="button" onClick={() => setEditingUserId(null)}>Cancel</button>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
export default UserManagement;