import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronDown, FaGamepad, FaUsers, FaCog, FaChartBar, FaMoneyBill } from 'react-icons/fa';
import './Sidebar.css';  // Add this import

function Sidebar() {
    const navigate = useNavigate();
    // Initialize all menus as closed (false)
    const [openMenus, setOpenMenus] = useState({
        dashboard: false,
        users: false,
        challenges: false,
        finance: false,
        system: false
    });

    const handleLogout = () => {
        localStorage.removeItem('adminId');
        localStorage.removeItem('adminUsername');
        localStorage.removeItem('adminRole');
        navigate('/admin/login');
    };

    const toggleMenu = (menuKey) => {
        setOpenMenus(prev => ({
            ...prev,
            [menuKey]: !prev[menuKey]
        }));
    };

    const menuStructure = {
        dashboard: {
            title: 'Dashboard',
            icon: <FaChartBar />,
            items: [
                { link: '/admin/dashboard', text: 'Dashboard Overview' }
            ]
        },
        users: {
            title: 'User Management',
            icon: <FaUsers />,
            items: [
                { link: '/admin/add-user', text: 'Add New User' },
                { link: '/admin/users', text: 'User Management' },
                { link: '/admin/credit-user', text: 'Credit User' },
                { link: '/admin/debit-user', text: 'Debit User' }
            ]
        },
        challenges: {
            title: 'Challenge System',
            icon: <FaGamepad />,
            items: [
                { link: '/admin/challenge-system', text: 'Challenge System' },
                { link: '/admin/challenge-management', text: 'Challenge Management' },
                { link: '/admin/credit-challenge', text: 'Credit Challenge' },
                { link: '/admin/team-management', text: 'Team Management' }
            ]
        },
        finance: {
            title: 'Finance',
            icon: <FaMoneyBill />,
            items: [
                { link: '/admin/PaymentMethods', text: 'Payment Methods' },
                { link: '/admin/bets', text: 'Bet Management' },
                { link: '/admin/transactions', text: 'Transaction Management' }
            ]
        },
        system: {
            title: 'System',
            icon: <FaCog />,
            items: [
                { link: '/admin/leaderboard', text: 'Leaderboard Management' },
                { link: '/admin/settings', text: 'System Settings' },
                { link: '/admin/reports', text: 'Reports and Analytics' }
            ]
        }
    };

    return (
        <div className="sidebar">
            <div className="logo">FanBet247</div>
            <nav className="sidebar-nav">
                {Object.entries(menuStructure).map(([key, menu]) => (
                    <div key={key} className="menu-group">
                        <div 
                            className="menu-header" 
                            onClick={() => toggleMenu(key)}
                        >
                            <span className="menu-icon">{menu.icon}</span>
                            <span className="menu-title">{menu.title}</span>
                            <FaChevronDown 
                                className={`menu-arrow ${openMenus[key] ? 'rotated' : ''}`} 
                            />
                        </div>
                        <div className={`menu-items ${openMenus[key] ? 'open' : ''}`}>
                            {menu.items.map((item, index) => (
                                <a 
                                    key={index} 
                                    href={item.link} 
                                    className="nav-item"
                                >
                                    {item.text}
                                </a>
                            ))}
                        </div>
                    </div>
                ))}
                <button onClick={handleLogout} className="nav-item logout-btn">Logout</button>
            </nav>
        </div>
    );
}
export default Sidebar;