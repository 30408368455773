import React from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

function ReportsAnalytics() {
    return (
        <div className="layout">
            <Sidebar />
            <div className="main-content">
                <Header />
                <h1>Reports and Analytics</h1>
                <p>This page is under construction.</p>
            </div>
        </div>
    );
}

export default ReportsAnalytics;
