import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ViewBets.css';

const API_BASE_URL = '/backend';
  function IncomingBets() {
    const [incomingBets, setIncomingBets] = useState([]);
    const [teams, setTeams] = useState([]);
    const userId = localStorage.getItem('userId');
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const fetchBets = useCallback(async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/handlers/get_bets.php?userId=${userId}`);
        if (response.data.success) {
          setIncomingBets(response.data.incomingBets); 
        } else {
          console.error("Error fetching bets:", response.data.message || "Unknown error");
        }
      } catch (error) {
        console.error('Error fetching bets:', error);
      }
    }, [userId]);

    useEffect(() => {
      if (userId) {
        fetchBets();
        fetchTeams();
      } else {
        navigate('/user/login');
      }
    }, [userId, fetchBets, navigate]);

    const fetchTeams = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
        if (response.data.status === 200) {
          setTeams(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    const getTeamLogo = (teamName) => {
      const team = teams.find(team => team.name === teamName);
      return team ? `${API_BASE_URL}/${team.logo}` : '';
    };

    const handleAcceptBet = async (betId) => {
      try {
        if (!userId) {
          setError('Please log in to accept a bet');
          navigate('/user/login');
          return;
        }

        const bet = incomingBets.find(b => b.bet_id === betId);
        if (!bet) {
          setError('Bet not found.');
          return;
        }

        const betAmount = bet.amount_user1;

        const user1BalanceResponse = await axios.get(`${API_BASE_URL}/handlers/user_data.php?id=${bet.user1_id}`);
        if (!user1BalanceResponse.data.success) {
          setError('Failed to fetch user data.');
          return;
        }
        const user1Balance = parseFloat(user1BalanceResponse.data.user.balance);

        if (user1Balance < betAmount) {
          setError('The bet creator no longer has sufficient funds.');
          return;
        }

        const response = await axios.post(`${API_BASE_URL}/handlers/accept_bet.php`, {
          betId,
          userId,
          amount: betAmount,
        });

        if (response.data.success) {
          setSuccess('Bet accepted successfully!');

          setIncomingBets(prevBets => prevBets.filter(b => b.bet_id !== betId));

          setTimeout(() => {
            navigate('/accepted-bets'); 
          }, 2000);
        } else {
          setError(response.data.message || 'Failed to accept bet');
        }
      } catch (error) {
        setError(error.response?.data?.message || 'Error accepting bet.');
      }
    };

    return (
      <div className="view-bets-container">
        <h2>Incoming Bets</h2>

        {/* Display error and success messages */}
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}

        <table className="bets-table">
          <thead>
            <tr>
              <th>From</th>
              <th>Team A</th>
              <th>Team B</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {incomingBets && incomingBets.length > 0 ? (
              incomingBets.map(bet => (
                <tr key={bet.bet_id}>
                  <td>{bet.user1_name}</td>
                  <td>
                    <div className="team-info">
                      <img src={getTeamLogo(bet.team_a)} alt={bet.team_a} className="team-logo" />
                      <span>{bet.team_a}</span>
                    </div>
                  </td>
                  <td>
                    <div className="team-info">
                      <img src={getTeamLogo(bet.team_b)} alt={bet.team_b} className="team-logo" />
                      <span>{bet.team_b}</span>
                    </div>
                  </td>
                  <td>{bet.amount_user1} FanCoins</td>
                  <td>
                    <span className={`status ${bet.bet_status}`}>
                      {bet.bet_status}
                    </span>
                  </td>
                  <td>
                    <button className="accept-button" onClick={() => handleAcceptBet(bet.bet_id)}>
                      Accept
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No incoming bets found.</td> 
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
}

export default IncomingBets;