import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ViewBets.css';

const API_BASE_URL = '/backend';

function ViewBets() {
const [outgoingBets, setOutgoingBets] = useState([]);
const [teams, setTeams] = useState([]);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);
const userId = localStorage.getItem('userId');
const navigate = useNavigate();

const [showLinkModal, setShowLinkModal] = useState(false);
const [selectedBetLink, setSelectedBetLink] = useState('');
const [showBetDetailsModal, setShowBetDetailsModal] = useState(false);
const [selectedBet, setSelectedBet] = useState(null);

const fetchBets = useCallback(async () => {
  try {
      const response = await axios.get(`${API_BASE_URL}/handlers/get_bets.php?userId=${userId}`);
      if (response.data.success) {
          setOutgoingBets(response.data.bets || []);
      } else {
          setError('Failed to fetch bets');
          console.error('Failed to fetch bets:', response.data.message);
      }
  } catch (error) {
      console.error('Error fetching bets:', error);
      setError('An error occurred while fetching bets.');
  } finally {
      setLoading(false);
  }
}, [userId]);

const fetchTeams = useCallback(async () => {
  try {
      const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
      if (response.data.status === 200) {
          setTeams(response.data.data || []);
      } else {
          setError('Failed to fetch teams');
          console.error('Failed to fetch teams:', response.data.message);
      }
  } catch (error) {
      console.error('Error fetching teams:', error);
      setError('An error occurred while fetching teams.');
  }
}, []);

useEffect(() => {
  if (!userId) {
      navigate('/user/login');
  } else {
      fetchBets();
      fetchTeams();
  }
}, [userId, fetchBets, fetchTeams, navigate]);

if (loading) {
  return <div className="loading">Loading bets...</div>;
}

if (error) {
  return <div className="error">{error}</div>;
}

  const getTeamLogo = (teamName) => {
    const team = teams.find(team => team.name === teamName);
    return team ? `${API_BASE_URL}/${team.logo}` : '';
  };

  const handleGenerateLink = (bet) => {
    const link = `${window.location.origin}/join-challenge2/${bet.challenge_id}/${bet.bet_id}/${bet.unique_code}/${bet.user1_id}`;
    setSelectedBetLink(link);
    setShowLinkModal(true);
  };

  const handleCloseModal = () => {
    setShowLinkModal(false);
  };
  const getReference = (bet) => {
    return (bet.unique_code || `${bet.bet_id}DNRBKCC`).toUpperCase();
  };

  const handleShowBetDetails = (bet) => {
    setSelectedBet(bet);
    setShowBetDetailsModal(true);
  };

  return (
    <div className="view-bets-container">
      <h2>Outgoing Bets</h2>
      <table className="bets-table">
        <thead>
          <tr>
            <th>Ref</th>
            <th>Team A</th>
            <th>Team B</th>
            <th>Amount</th>
            <th>Potential Return</th>
            <th>Status</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {outgoingBets.map(bet => {
            const isTeamA = bet.bet_choice_user1 === 'team_a_win';
            const firstTeam = isTeamA ? bet.team_a : bet.team_b;
            const secondTeam = isTeamA ? bet.team_b : bet.team_a;
            const reference = getReference(bet);
            
            return (
              <tr key={bet.bet_id}>
                <td>
                  <span className="reference" onClick={() => handleShowBetDetails(bet)}>
                    {reference}
                  </span>
                </td>
                <td>
                  <div className="team-info">
                    <img src={getTeamLogo(firstTeam)} alt={firstTeam} className="team-logo" />
                    <span>{firstTeam}</span>
                    {isTeamA && <span className="pick-badge">Your Pick</span>}
                  </div>
                </td>
                <td>
                  <div className="team-info">
                    <img src={getTeamLogo(secondTeam)} alt={secondTeam} className="team-logo" />
                    <span>{secondTeam}</span>
                    {!isTeamA && <span className="pick-badge">Your Pick</span>}
                  </div>
                </td>
                <td className="amount-cell">
                  {bet.amount_user1} <span className="currency">FanCoins</span>
                </td>
                <td className="return-cell">
                  {bet.potential_return_win_user1} <span className="currency">FanCoins</span>
                </td>
                <td>
                  <span className={`status ${bet.bet_status}`}>
                    {bet.bet_status === 'open' ? 'Open' : bet.bet_status === 'joined' ? 'Joined' : 'Completed'}
                  </span>
                </td>
                <td>{new Date(bet.created_at).toLocaleString()}</td>
                <td>
                  <button onClick={() => handleGenerateLink(bet)}>
                    Generate Link
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {showLinkModal && (
        <div className="modal-overlay">
          <div className="modal-content link-modal">
            <span className="close" onClick={handleCloseModal}>×</span>
            <h3>Share Bet Link</h3>
            <div className="link-container">
              <input type="text" value={selectedBetLink} readOnly />
              <button onClick={() => {navigator.clipboard.writeText(selectedBetLink); handleCloseModal();}}>
                Copy Link
              </button>
            </div>
          </div>
        </div>
      )}

    {showBetDetailsModal && selectedBet && (
    <div className="modal-overlay">
        <div className="bet-details-modal">
            <button className="close-button" onClick={() => setShowBetDetailsModal(false)}>×</button>
            
            <div className="modal-left">
                <h3 className="reference-title">Bet Reference: {getReference(selectedBet)}</h3>
                
                <div className="teams-match">
                    <div className={`team-card user1`}>
                        <img src={getTeamLogo(selectedBet.team_a)} alt={selectedBet.team_a} />
                        <div className="team-name">{selectedBet.team_a}</div>
                        <div className="team-odds user1">Odds: {selectedBet.odds_user1}</div>
                        <div className="team-user">{selectedBet.username}</div>
                    </div>
                    
                    <div className="vs-badge">VS</div>
                    
                    <div className={`team-card user2`}>
                        <img src={getTeamLogo(selectedBet.team_b)} alt={selectedBet.team_b} />
                        <div className="team-name">{selectedBet.team_b}</div>
                        <div className="team-odds user2">Odds: {selectedBet.odds_user2}</div>
                        <div className="team-user">
                            {selectedBet.bet_status === 'joined' ? selectedBet.username2 : 'WAITING FOR OPPONENT'}
                        </div>
                    </div>
                </div>
            </div>            
            <div className="modal-right">
                <div className="status-badge-large" data-status={selectedBet.bet_status}>
                    {selectedBet.bet_status.toUpperCase()}
                </div>

                <div className="bet-details-grid">
                    <div className="detail-item amount">
                        <div className="detail-label">Your Bet Amount</div>
                        <div className="detail-value amount">{selectedBet.amount_user1} FanCoins</div>
                    </div>
                    <div className="detail-item return">
                        <div className="detail-label">Your Potential Return</div>
                        <div className="detail-value return">{selectedBet.potential_return_win_user1} FanCoins</div>
                    </div>
                    {selectedBet.user2_id && (
                        <>
                            <div className="detail-item">
                                <div className="detail-label">Opponent ({selectedBet.username2})</div>
                                <div className="detail-value">{selectedBet.amount_user2} FanCoins</div>
                            </div>
                            <div className="detail-item">
                                <div className="detail-label">Opponent Potential Return</div>
                                <div className="detail-value">{selectedBet.potential_return_win_user2} FanCoins</div>
                            </div>
                        </>
                    )}
                    <div className="detail-item">
                        <div className="detail-label">Created At</div>
                        <div className="detail-value">{new Date(selectedBet.created_at).toLocaleString()}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)}
    </div>
  );
}

export default ViewBets;