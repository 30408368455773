import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import './AdminDashboard.css';

const API_BASE_URL = '/backend';

function AdminDashboard() {
    const [dashboardData, setDashboardData] = useState(null);
    const [teams, setTeams] = useState([]);
    const [selectedBetDetails, setSelectedBetDetails] = useState(null);
    const [showBetModal, setShowBetModal] = useState(false);
      useEffect(() => {
          fetchDashboardData();
          fetchTeams();
      }, []);

      const fetchDashboardData = async () => {
          try {
              const response = await axios.get(`${API_BASE_URL}/handlers/admin_dashboard_data.php`);
              if (response.data.success) {
                  setDashboardData(response.data);
              } else {
                  console.error('Failed to fetch dashboard data:', response.data.message);
              }
          } catch (error) {
              console.error('Error fetching dashboard data:', error);
          }
      };

      const fetchTeams = async () => {
          try {
              const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
              if (response.data.status === 200) {
                  setTeams(response.data.data);
              }
          } catch (err) {
              console.error('Error fetching teams:', err);
          }
      };

      const getTeamLogo = (teamName) => {
          const team = teams.find(team => team.name === teamName);
          return team ? `${API_BASE_URL}/${team.logo}` : '';
      };
        const BetDetailsModal = ({ bet, onClose }) => (
            <div className="modal-overlay">
                <div className="bet-details-modal">
                    <button className="close-button" onClick={onClose}>×</button>
                    <div className="modal-left">
                        <h3 className="reference-title">Bet ID: {bet.bet_id}</h3>
                
                        <div className="teams-match">
                            <div className={`team-card user1`}>
                                <img src={getTeamLogo(bet.team_a)} alt={bet.team_a} />
                                <div className="team-name">{bet.team_a}</div>
                                <div className="team-odds">Odds: {bet.odds_user1}</div>
                                <div className="team-user">{bet.user1_name}</div>
                            </div>
                    
                            <div className="vs-badge">VS</div>
                    
                            <div className={`team-card user2`}>
                                <img src={getTeamLogo(bet.team_b)} alt={bet.team_b} />
                                <div className="team-name">{bet.team_b}</div>
                                <div className="team-odds">Odds: {bet.odds_user2}</div>
                                <div className="team-user">
                                    {bet.user2_name || 'WAITING FOR OPPONENT'}
                                </div>
                            </div>
                        </div>
                    </div>            
                    <div className="modal-right">
                        <div className="status-badge-large" data-status={bet.bet_status}>
                            {bet.bet_status.toUpperCase()}
                        </div>

                        <div className="bet-details-grid">
                            <div className="detail-item amount">
                                <div className="detail-label">User 1 Bet Amount</div>
                                <div className="detail-value">{bet.amount_user1} FanCoins</div>
                            </div>
                            <div className="detail-item return">
                                <div className="detail-label">User 1 Potential Return</div>
                                <div className="detail-value">{bet.potential_return_win_user1} FanCoins</div>
                            </div>
                            {bet.user2_name && (
                                <>
                                    <div className="detail-item">
                                        <div className="detail-label">User 2 Bet Amount</div>
                                        <div className="detail-value">{bet.amount_user2} FanCoins</div>
                                    </div>
                                    <div className="detail-item">
                                        <div className="detail-label">User 2 Potential Return</div>
                                        <div className="detail-value">{bet.potential_return_win_user2} FanCoins</div>
                                    </div>
                                </>
                            )}
                            <div className="detail-item">
                                <div className="detail-label">Created At</div>
                                <div className="detail-value">{new Date(bet.created_at).toLocaleString()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    const UserActivityModal = ({ activity, onClose }) => (
        <div className="modal-overlay">
            <div className="bet-details-modal">
                <button className="close-button" onClick={onClose}>×</button>
                <div className="modal-left">
                    <h3 className="reference-title">User: {activity.username}</h3>
                    
                    <div className="teams-match">
                        <div className={`team-card user1`}>
                            <img src={getTeamLogo(activity.latest_challenge_team_a)} alt={activity.latest_challenge_team_a} />
                            <div className="team-name">{activity.latest_challenge_team_a}</div>
                            <div className="team-odds">Odds: {activity.odds_team_a || '1.80'}</div>
                            <div className="team-advantage">Goal Advantage: {activity.team_a_goal_advantage || '0'}</div>
                        </div>
                        
                        <div className="vs-badge">VS</div>
                        
                        <div className={`team-card user2`}>
                            <img src={getTeamLogo(activity.latest_challenge_team_b)} alt={activity.latest_challenge_team_b} />
                            <div className="team-name">{activity.latest_challenge_team_b}</div>
                            <div className="team-odds">Odds: {activity.odds_team_b || '1.80'}</div>
                            <div className="team-advantage">Goal Advantage: {activity.team_b_goal_advantage || '0'}</div>
                        </div>
                    </div>
                </div>            
                <div className="modal-right">
                    <div className="bet-details-grid">
                        <div className="detail-item amount">
                            <div className="detail-label">Total Bets</div>
                            <div className="detail-value">{activity.totalBets}</div>
                        </div>
                        <div className="detail-item balance">
                            <div className="detail-label">Balance</div>
                            <div className="detail-value">{activity.balance} FanCoins</div>
                        </div>
                        <div className="detail-item return">
                            <div className="detail-label">Latest Potential Return</div>
                            <div className="detail-value">{activity.latest_potential_return} FanCoins</div>
                        </div>
                        <div className="detail-item odds">
                            <div className="detail-label">Match Odds</div>
                            <div className="detail-value">{activity.odds_team_a || '1.80'} - {activity.odds_team_b || '1.80'}</div>
                        </div>
                        <div className="detail-item">
                            <div className="detail-label">Last Activity</div>
                            <div className="detail-value">{new Date(activity.lastActivity).toLocaleString()}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    if (!dashboardData) return <div>Loading...</div>;

    return (
        <div className="layout">
            <Sidebar />
            <div className="main-content">
                <Header />
                <div className="dashboard">
                    <div className="card card-half">
                        <h2>User Overview</h2>
                        <div className="stats">
                            <div className="stat-item">
                                <div className="stat-value">{dashboardData.totalUsers}</div>
                                <div className="stat-label">Total Users</div>
                            </div>
                        </div>
                    </div>
                    <div className="card card-half">
                        <h2>Challenge Overview</h2>
                        <div className="stats">
                            <div className="stat-item">
                                <div className="stat-value">{dashboardData.activeChallenges}</div>
                                <div className="stat-label">Active Challenges</div>
                            </div>
                        </div>
                    </div>
                    <div className="card card-half">
                        <h2>Total Wins</h2>
                        <div className="stats">
                            <div className="stat-item">
                                <div className="stat-value">${dashboardData.totalWins}</div>
                                <div className="stat-label">Total Winnings</div>
                            </div>
                        </div>
                    </div>
                    <div className="card card-half">
                        <h2>Total Bets</h2>
                        <div className="stats">
                            <div className="stat-item">
                                <div className="stat-value">{dashboardData.totalBets}</div>
                                <div className="stat-label">Bets Placed</div>
                            </div>
                        </div>
                    </div>
<div className="card card-full">
    <h2>Top Teams Overview</h2>
    <div className="teams-container">
        {dashboardData.teamStats && dashboardData.teamStats.map(team => (
            <div key={team.id} className="team-card">
                <img 
                    src={`${API_BASE_URL}/${team.logo}`} 
                    alt={team.name} 
                    className="team-logo-circle" 
                />
                <p className="team-name">{team.name}</p>
                <div className="team-stats">
                    <span className="user-count">{team.user_count}</span>
                    <span className="user-label">Users</span>
                </div>
            </div>
        ))}
    </div>
</div>
                    <div className="card card-full">
                        <h2>Latest User Activity</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Total Bets</th>
                                    <th>Balance</th>
                                    <th>Latest Challenge</th>
                                    <th>Potential Return</th>
                                    <th>Last Activity</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dashboardData.recentActivity.map((activity, index) => (
                                    <tr key={index}>
                                        <td>{activity.username}</td>
                                        <td>{activity.totalBets}</td>
                                        <td>{activity.balance} FanCoins</td> {/* Changed from totalWinAmount */}<td>
                                            <div className="team-matchup-mini">
                                                <img src={getTeamLogo(activity.latest_challenge_team_a)} alt={activity.latest_challenge_team_a} />
                                                vs
                                                <img src={getTeamLogo(activity.latest_challenge_team_b)} alt={activity.latest_challenge_team_b} />
                                            </div>
                                        </td>
                                        <td>{activity.latest_potential_return} FanCoins</td>
                                        <td>{new Date(activity.lastActivity).toLocaleString()}</td>
                                        <td>
                                            <button onClick={() => {
                                                setSelectedBetDetails(activity);
                                                setShowBetModal(true);
                                            }}>
                                                Details
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="card card-full">
                        <h2>Recent Bets</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Bet ID</th>
                                    <th>User 1</th>
                                    <th>User 2</th>
                                    <th>Match</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dashboardData.recentBets.map((bet, index) => (
                                    <tr key={index}>
                                        <td>{bet.bet_id}</td>
                                        <td>{bet.user1_name}</td>
                                        <td>{bet.user2_name || 'Waiting...'}</td>
                                        <td>
                                            <div className="team-matchup-mini">
                                                <img src={getTeamLogo(bet.team_a)} alt={bet.team_a} />
                                                vs
                                                <img src={getTeamLogo(bet.team_b)} alt={bet.team_b} />
                                            </div>
                                        </td>
                                        <td>{bet.amount_user1} FanCoins</td>
                                        <td>{bet.bet_status}</td>
                                        <td>
                                            <button onClick={() => {
                                                setSelectedBetDetails(bet);
                                                setShowBetModal(true);
                                            }}>
                                                View Details
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {showBetModal && (
                selectedBetDetails.bet_id ? 
                    <BetDetailsModal 
                        bet={selectedBetDetails} 
                        onClose={() => setShowBetModal(false)} 
                    /> :
                    <UserActivityModal 
                        activity={selectedBetDetails} 
                        onClose={() => setShowBetModal(false)} 
                    />
            )}
        </div>
    );

}

export default AdminDashboard;
