import React from 'react';

function Header() {
    return (
        <header className="header-bar">
            <div className="header-logo">FanBet247</div>
            <div>Admin Dashboard</div>
        </header>
    );
}

export default Header;
