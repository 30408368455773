import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './UserRegistration.css';

const API_BASE_URL = '/backend';

function UserRegistration() {
    const [teams, setTeams] = useState([]);
    const [newUser, setNewUser] = useState({
        username: '',
        full_name: '',
        email: '',
        password: '',
        favorite_team: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchTeams();
    }, []);

    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
            setTeams(response.data.data || []);
        } catch (err) {
            setError('Failed to fetch teams');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            const response = await axios.post(`${API_BASE_URL}/handlers/user_registration.php`, newUser);
            if (response.data.success) {
                setSuccess('Registration successful! Redirecting to login...');
                setTimeout(() => navigate('/user/login'), 3000);
            } else {
                setError(response.data.message || 'Registration failed');
            }
        } catch (err) {
            setError('An error occurred during registration');
        }
    };

    return (
        <div className="user-registration">
            <h1>FanBet247 User Registration</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="username"
                    value={newUser.username}
                    onChange={handleInputChange}
                    placeholder="Username"
                    required
                />
                <input
                    type="text"
                    name="full_name"
                    value={newUser.full_name}
                    onChange={handleInputChange}
                    placeholder="Full Name"
                    required
                />
                <input
                    type="email"
                    name="email"
                    value={newUser.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                    required
                />
                <input
                    type="password"
                    name="password"
                    value={newUser.password}
                    onChange={handleInputChange}
                    placeholder="Password"
                    required
                />
                <select
                    name="favorite_team"
                    value={newUser.favorite_team}
                    onChange={handleInputChange}
                    required
                >
                    <option value="">Select Favorite Team</option>
                    {teams.map(team => (
                        <option key={team.id} value={team.name}>{team.name}</option>
                    ))}
                </select>
                <button type="submit">Register</button>
            </form>
            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}
            <p>Already have an account? <a href="/user/login">Login here</a></p>
        </div>
    );
}

export default UserRegistration;
