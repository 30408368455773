import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import './CreditChallenge.css';

const API_BASE_URL = '/backend';

const initialFormState = {
  selectedChallenge: '',
  teamAScore: 0,
  teamBScore: 0,
  creditTypeA: 'win',
  creditTypeB: 'loss',
};

function CreditChallenge() {
    const [challenges, setChallenges] = useState([]);
    const [formState, setFormState] = useState(initialFormState);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const fetchOpenChallenges = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/get_challenges.php`, {
                params: { status: 'Closed' }
            });
            setChallenges(response.data);
        } catch (err) {
            setError('Failed to fetch challenges');
            console.error('Error:', err);
        }
    }, []);

    useEffect(() => {
        fetchOpenChallenges();
    }, [fetchOpenChallenges]);

    // Modified to parse the challenge_id as a number for strict comparison
    const selectedChallenge = useMemo(() => {
        const selectedId = parseInt(formState.selectedChallenge, 10);
        return challenges.find(c => parseInt(c.challenge_id, 10) === selectedId);
    }, [challenges, formState.selectedChallenge]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log('Input changed:', name, value); // Debug log
        setFormState(prev => ({ ...prev, [name]: value }));
    };

    const resetForm = () => setFormState(initialFormState);

    const handleCompleteChallenge = async (e) => {
        e.preventDefault();
        
        try {
            const formData = new FormData();
            formData.append('challenge_id', formState.selectedChallenge);
            formData.append('team_a_score', formState.teamAScore);
            formData.append('team_b_score', formState.teamBScore);
            formData.append('credit_type_a', formState.creditTypeA);
            formData.append('credit_type_b', formState.creditTypeB);

            const response = await axios.post(
                `${API_BASE_URL}/handlers/complete_challenge.php`,
                formData
            );

            if (response.data.success) {
                setSuccess('Challenge completed and users credited successfully!');
                fetchOpenChallenges();
                resetForm();
            } else {
                setError(response.data.message);
            }
        } catch (err) {
            setError('Failed to complete challenge');
            console.error('Error:', err);
        }
    };

    // Debug log to check selected challenge
    console.log('Current selected challenge:', selectedChallenge);
    console.log('Form state:', formState);

    return (
        <div className="layout">
            <Sidebar />
            <div className="main-content">
                <Header />
                <div className="credit-challenge">
                    <h1>Credit Challenge</h1>
                    
                    {success && <div className="success-message">{success}</div>}
                    {error && <div className="error-message">{error}</div>}

                    <form onSubmit={handleCompleteChallenge} className="challenge-form">
                        <div className="form-group">
                            <label>Select Challenge:</label>
                            <select 
                                name="selectedChallenge"
                                value={formState.selectedChallenge} 
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select a challenge</option>
                                {challenges.map(challenge => (
                                    <option 
                                        key={challenge.challenge_id} 
                                        value={challenge.challenge_id}
                                    >
                                        {challenge.team_a} vs {challenge.team_b} - {new Date(challenge.match_date).toLocaleDateString()}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {formState.selectedChallenge && selectedChallenge && (
                            <>
                                <div className="teams-section">
                                    <div className="team-a">
                                        <h3>{selectedChallenge.team_a}</h3>
                                        <div className="form-group">
                                            <label>Score:</label>
                                            <input
                                                type="number"
                                                name="teamAScore"
                                                value={formState.teamAScore}
                                                onChange={handleInputChange}
                                                required
                                                min="0"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Credit Type:</label>
                                            <select
                                                name="creditTypeA"
                                                value={formState.creditTypeA}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="win">Win Return</option>
                                                <option value="draw">Draw Return</option>
                                                <option value="loss">Loss Return</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="team-b">
                                        <h3>{selectedChallenge.team_b}</h3>
                                        <div className="form-group">
                                            <label>Score:</label>
                                            <input
                                                type="number"
                                                name="teamBScore"
                                                value={formState.teamBScore}
                                                onChange={handleInputChange}
                                                required
                                                min="0"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Credit Type:</label>
                                            <select
                                                name="creditTypeB"
                                                value={formState.creditTypeB}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="win">Win Return</option>
                                                <option value="draw">Draw Return</option>
                                                <option value="loss">Loss Return</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <button type="submit" className="complete-btn">
                                    Complete Challenge
                                </button>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreditChallenge;