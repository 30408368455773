import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Countdown from 'react-countdown';
import './WelcomeSplash.css';
const API_BASE_URL = '/backend';

// Countdown renderer component for live challenges
// Displays days, hours, minutes, seconds with expired state handling
const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    // Handle expired challenges
    if (completed) {
        return <span className="status-badge expired">Expired</span>;
    }

    // Active countdown display
    return (
        <div className="countdown-timer">
            <span>{days}d </span>
            <span>{hours}h </span>
            <span>{minutes}m </span>
            <span>{seconds}s</span>
        </div>
    );
};

function WelcomeSplash() {
  const [recentChallenges, setRecentChallenges] = useState([]);
  const [teams, setTeams] = useState([]);
  const [recentBets, setRecentBets] = useState([]);
  const [recentUsers, setRecentUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState(0);
  const [totalBets, setTotalBets] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderImages = ['/slider/Slider1.png', '/slider/Slider2.png'];
  const navigate = useNavigate();
  const [userName, setUserName] = useState(null);

  useEffect(() => {
      fetchRecentChallenges();
      fetchTeams();
      fetchRecentBets();
      fetchUserStats();

      const userId = localStorage.getItem('userId');
      if (userId) {
          fetchUserName(userId);
      }
  }, []);

  const fetchRecentChallenges = async () => {
      try {
          const response = await axios.get(`${API_BASE_URL}/handlers/recent_challenges.php`);
          if (response.data.success) {
              const challenges = response.data.challenges.map(challenge => ({
                ...challenge,
                end_time: new Date(challenge.end_time)
              }));
              setRecentChallenges(challenges);
          }
      } catch (error) {
          console.error('Error fetching recent challenges:', error);
      }
  };

  const fetchTeams = async () => {
      try {
          const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
          if (response.data.status === 200) {
              setTeams(response.data.data);
          }
      } catch (err) {
          console.error('Error fetching teams:', err);
      }
  };

  const fetchRecentBets = async () => {
      try {
          const response = await axios.get(`${API_BASE_URL}/handlers/get_bets.php`);
          if (response.data.success) {
              setRecentBets(response.data.bets);
          }
      } catch (error) {
          console.error('Error fetching recent bets:', error);
      }
  };

  const fetchUserStats = async () => {
      try {
          const response = await axios.get(`${API_BASE_URL}/handlers/user_stats.php`);
          if (response.data.success) {
              setRecentUsers(response.data.recentUsers);
              setActiveUsers(response.data.activeUsers);
              setTotalBets(response.data.totalBets);
          }
      } catch (error) {
          console.error('Error fetching user stats:', error);
      }
  };

  const fetchUserName = async (userId) => {
      try {
          const response = await axios.get(`${API_BASE_URL}/handlers/get_username.php?user_id=${userId}`);
          if (response.data.success) {
              setUserName(response.data.username);
          }
      } catch (error) {
          console.error("Error fetching username:", error);
      }
  };

  useEffect(() => {
    const timer = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % sliderImages.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  const getTeamLogo = (teamName) => {
      const team = teams.find(team => team.name === teamName);
      return team ? `${API_BASE_URL}/${team.logo}` : '';
  };

  const handleLogout = () => {
      localStorage.removeItem('userId');
      setUserName(null);
      navigate('/');
  };

  const handlePlaceBetClick = (challengeId) => {
      if (localStorage.getItem('userId')) {
          navigate(`/join-challenge/${challengeId}`);
      } else {
          sessionStorage.setItem('redirectAfterLogin', `/join-challenge/${challengeId}`);
          navigate('/user/login');
      }
  };

  return (
      <div className="welcome-splash">
          <header className="header">
              <div className="logo">FanBet247</div>
              <nav className="nav-links">
                  {userName ? (
                      <>
                          <span className="welcome-message">Welcome, {userName.charAt(0).toUpperCase() + userName.slice(1).toLowerCase()}!</span>
                          <Link to="/user/dashboard" className="dashboard-button">Dashboard</Link>
                          <Link to="/admin/login" className="admin">Admin Login</Link>
                          <button onClick={handleLogout} className="logout-button">Logout</button>
                      </>
                  ) : (
                      <>
                          <Link to="/user/login">User Login</Link>
                          <Link to="/user/register">Register</Link>
                          <Link to="/admin/login" className="admin">Admin Login</Link>
                      </>
                  )}
              </nav>
          </header>
            <main>
                <section className="hero">
                    <div className="hero-content glassmorphism">
                        <div className="slider" style={{ 
                            backgroundImage: `url(${sliderImages[currentSlide]})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}>
                        </div>
                    </div>
                </section>
              <section className="platform-stats">
                  <div className="stat-card">
                      <h3>Active Users</h3>
                      <span>{activeUsers}</span>
                  </div>
                  <div className="stat-card">
                      <h3>Total Bets</h3>
                      <span>{totalBets}</span>
                  </div>
              </section>
              <section className="recent-activity">
                  <div className="activity-grid">
                      <div className="recent-bets-section">
                          <h2>Recent Bets</h2>
                          <div className="bets-list">
                              {recentBets && recentBets.slice(0, 2).map((bet) => (
                                  <div key={bet.bet_id} className="bet-card">
                                      <div className="bet-header">
                                          <span className={`bet-status ${bet.bet_status}`}>
                                              {bet.bet_status.toUpperCase()}
                                          </span>
                                          <div className="returns-info">
                                              <div>Win: {bet.potential_return_win_user1} FC</div>
                                              <div>Draw: {bet.potential_return_draw_user1} FC</div>
                                              <div>Loss: {bet.potential_return_loss_user1} FC</div>
                                          </div>
                                      </div>
                                      
                                      <div className="bet-matchup">
                                          <div className="bet-team">
                                              <img src={getTeamLogo(bet.team_a)} alt={bet.team_a} className="team-logo-medium" />
                                              <div className="team-details">
                                                  <span className="team-name-small">{bet.team_a}</span>
                                                  <span className="player-name-styled">{bet.user1_name.charAt(0).toUpperCase() + bet.user1_name.slice(1).toLowerCase()}</span>
                                                  <span className="odds-small">Odds: {bet.odds_team_a}</span>
                                              </div>
                                          </div>

                                          <div className="vs-badge">VS</div>

                                          <div className="bet-team">
                                              <img src={getTeamLogo(bet.team_b)} alt={bet.team_b} className="team-logo-medium" />
                                              <div className="team-details">
                                                  <span className="team-name-small">{bet.team_b}</span>
                                                  <span className="player-name-styled">
                                                      {bet.user2_name ? 
                                                          bet.user2_name.charAt(0).toUpperCase() + bet.user2_name.slice(1).toLowerCase() 
                                                          : 'Waiting...'}
                                                  </span>
                                                  <span className="odds-small">Odds: {bet.odds_team_b}</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              ))}
                          </div>
                      </div>
                      <div className="recent-users-section">
                          <h2>Recent Users</h2>
                          <div className="users-list">
                              {recentUsers.slice(0, 4).map(user => (
                                  <div key={user.user_id} className="user-card">
                                      <div className="user-info">
                                          <img 
                                              src={getTeamLogo(user.favorite_team)} 
                                              alt={user.favorite_team} 
                                              className="team-logo-small" 
                                          />
                                          {localStorage.getItem('userId') ? (
                                              <Link 
                                                  to={`/profile/${user.username}`} 
                                                  className="username"
                                              >
                                                  {user.username.charAt(0).toUpperCase() + user.username.slice(1).toLowerCase()}
                                              </Link>
                                          ) : (
                                              <span className="username">
                                                  {user.username.charAt(0).toUpperCase() + user.username.slice(1).toLowerCase()}
                                              </span>
                                          )}
                                      </div>
                                      <span className="favorite-team">{user.favorite_team}</span>
                                  </div>
                              ))}
                          </div>
                      </div>
                  </div>
              </section>              
              <div className="features-container">
                <div className="live-challenges-section">
                    <h2 className="section-title">Live Challenges</h2>
                    <div className="soccer-challenges-grid">
                        {recentChallenges.slice(0, 4).map(challenge => (
                            <div key={challenge.challenge_id} className="soccer-match-card">
                                <div className="match-status-bar">
                                    <span className={`match-status ${challenge.status.toLowerCase()}`}>
                                        {challenge.status}
                                    </span>
                                </div>
                                
                                <div className="match-content">
                                    <div className="team home-team">
                                        <div className="team-logo-wrapper">
                                            <img src={getTeamLogo(challenge.team_a)} alt={challenge.team_a} />
                                        </div>
                                        <h3>{challenge.team_a}</h3>
                                        <div className="team-odds">ODDS: {challenge.odds_team_a}</div>
                                    </div>

                                    <div className="match-center">
                                        <div className="versus-badge">VS</div>
                                        <div className="match-datetime">
                                            <div className="match-date">
                                                {new Date(challenge.match_date).toLocaleDateString('en-US', {
                                                    weekday: 'short',
                                                    month: 'short',
                                                    day: 'numeric'
                                                })}
                                            </div>
                                            <div className="match-time">
                                                {new Date(challenge.match_date).toLocaleTimeString('en-US', {
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="team away-team">
                                        <div className="team-logo-wrapper">
                                            <img src={getTeamLogo(challenge.team_b)} alt={challenge.team_b} />
                                        </div>
                                        <h3>{challenge.team_b}</h3>
                                        <div className="team-odds">ODDS: {challenge.odds_team_b}</div>
                                    </div>
                                </div>

                                <div className="match-footer">
                                    <div className="countdown-timer">
                                        <span>Ends In: </span>
                                        <Countdown 
                                            date={new Date(challenge.end_time)}
                                            renderer={CountdownRenderer}
                                        />
                                    </div>
                                    <button
                                        className="place-bet-button"
                                        onClick={() => handlePlaceBetClick(challenge.challenge_id)}
                                    >
                                        {localStorage.getItem('userId') ? 'Place Bet' : 'Login to Bet'}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                                  <div className="teams-container">
                                      <div className="teams-showcase-grid">
                                          {teams.slice(0, 6).map(team => (
                                              <div key={team.id} className="team-showcase-card">
                                                  <div className="team-status-bar">
                                                      <span className="team-rank">TOP TEAM</span>
                                                  </div>
                                                  <div className="team-content">
                                                      <div className="team-logo-showcase">
                                                          <img src={`${API_BASE_URL}/${team.logo}`} alt={team.name} />
                                                      </div>
                                                      <h3 className="team-name">{team.name}</h3>
                                                      <div className="team-stats">
                                                          <div className="stat-value">{team.user_count || 0}</div>
                                                          <div className="stat-label">SUPPORTERS</div>
                                                      </div>
                                                  </div>
                                              </div>
                                          ))}
                                      </div>
                                  </div>
                                </div>
          </main>
          <footer className="footer">© 2024 FanBet247. All rights reserved.</footer>
      </div>
  );
}

export default WelcomeSplash;
