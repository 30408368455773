import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import './TeamManagement.css';

const API_BASE_URL = '/backend';

function TeamManagement() {
  const [teams, setTeams] = useState([]);
  const [newTeam, setNewTeam] = useState({ name: '', logo: null });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [editingTeamId, setEditingTeamId] = useState(null);
  const [editingTeam, setEditingTeam] = useState({ name: '', logo: null });

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
      setTeams(response.data.data);
    } catch (err) {
      setError('Failed to fetch teams');
      setTeams([]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setNewTeam(prev => ({
      ...prev,
      [name]: type === 'file' ? e.target.files[0] : value
    }));
  };

  const handleEditInputChange = (e) => {
    const { name, value, type } = e.target;
    setEditingTeam(prev => ({
      ...prev,
      [name]: type === 'file' ? e.target.files[0] : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!newTeam.name || !newTeam.logo) {
      setError('Team name and logo are required.');
      return;
    }

    // File type validation
    const allowedFileTypes = ['image/svg+xml', 'image/png'];
    if (!allowedFileTypes.includes(newTeam.logo.type)) {
      setError('Only SVG and PNG files are allowed.');
      return;
    }

    try {
      const formData = new FormData();
      for (const key in newTeam) {
        formData.append(key, newTeam[key]);
      }
      const response = await axios.post(`${API_BASE_URL}/handlers/team_management.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (response.data.success) {
        setSuccess('Team created successfully!');
        fetchTeams();
        setNewTeam({ name: '', logo: null });

        // Refresh the page after a short delay
        setTimeout(() => {
          window.location.reload();
        }, 1000); // Refresh after 1 second 
      } else {
        setError(response.data.message || 'Failed to create team');
      }
    } catch (err) {
      setError('Failed to create team');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/handlers/team_management.php?id=${id}`);
      fetchTeams();
    } catch (err) {
      setError('Failed to delete team');
    }
  };

  const handleEdit = async (id) => {
    setEditingTeamId(id);
    const teamToEdit = teams.find(team => team.id === id);
    setEditingTeam({ name: teamToEdit.name, logo: teamToEdit.logo });
  };

  const handleUpdateTeam = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('name', editingTeam.name);
      if (editingTeam.logo instanceof File) { 
        formData.append('logo', editingTeam.logo);
      }

      const response = await axios.post(`${API_BASE_URL}/handlers/update_team.php?id=${editingTeamId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (response.data.success) {
        setSuccess('Team updated successfully!');
        fetchTeams();
        setEditingTeamId(null);
        setEditingTeam({ name: '', logo: null });
      } else {
        setError(response.data.message || 'Failed to update team');
      }
    } catch (err) {
      setError('Failed to update team');
      console.error(err);
    }
  };

  return (
    <div className="layout">
      <Sidebar />
      <div className="main-content">
        <Header />
        <div className="team-management">
          <div className="card">
            <h1>Team Management</h1>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div>
                <label htmlFor="name">Team Name:</label>
                <input 
                  type="text" 
                  id="name" 
                  name="name" 
                  value={newTeam.name} 
                  onChange={handleInputChange} 
                  required 
                />
              </div>
              <div>
                <label htmlFor="logo">Team Logo:</label>
                <input 
                  type="file" 
                  id="logo" 
                  name="logo" 
                  onChange={handleInputChange} 
                  accept="image/*" 
                  required 
                />
              </div>
              <button type="submit">Add Team</button>
            </form>
          </div>

          <div className="card">
            <h2>Existing Teams</h2>
            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Logo</th>
                  <th>Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {teams.map(team => (
                  <tr key={team.id}>
                    <td>{team.id}</td>
                    <td>
                      <img
                        src={`${API_BASE_URL}/${team.logo}`}
                        alt={team.name}
                        className="team-logo"
                        style={{ width: '50px', height: '50px' }}
                      />
                    </td>
                    <td>{team.name}</td>
                    <td>
                      <button onClick={() => handleDelete(team.id)}>Delete</button>
                      <button onClick={() => handleEdit(team.id)}>Edit</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {editingTeamId && ( 
              <div className="modal-overlay">
                <div className="modal">
                  <h3>Edit Team</h3>
                  <form onSubmit={handleUpdateTeam} encType="multipart/form-data">
                    <div>
                      <label htmlFor="editName">Team Name:</label>
                      <input
                        type="text"
                        id="editName"
                        name="name"
                        value={editingTeam.name}
                        onChange={handleEditInputChange}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="editLogo">Team Logo:</label>
                      <input
                        type="file"
                        id="editLogo"
                        name="logo"
                        onChange={handleEditInputChange}
                        accept=".svg, .png" 
                      />
                    </div>
                    <button type="submit">Update Team</button>
                    <button type="button" onClick={() => setEditingTeamId(null)}>Cancel</button>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamManagement;